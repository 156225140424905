import React from "react";
import {FollowUpList} from './follow-up-list';

export const CompletedFollowUp = () => {
    return (
        <>
    <FollowUpList Status='completed'/>
    </>
    )
}
export default CompletedFollowUp;