import React, { useState, useEffect, useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import TimeSlotPicker from "./timeSlotPicker";
import { useNavigate } from "react-router-dom";

const Scheduler = (props) => {
  const {  getFollowUps } = useContext(PastoralContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const navigate =useNavigate();
  const [markedDates,setMarkedDates] = useState([]);
  const handleNav = (id) => {
    navigate("/followups/"+ id,{relative:"route"});
  };
  const isWeekend = date => {
    const day = date.getDay();
    return day === 6 || day === 0; // Disable Saturday (6) and Sunday (0)
  };
  useEffect(() => {
  
    setSelectedDate((props.selectedDate instanceof Date && !isNaN(props.selectedDate))?new Date(props.selectedDate+' UTC'):new Date())
    props.setUpdatedFollowUpDate&&props.setUpdatedFollowUpDate();
    setEvents([]);
    getFollowUps("ongoing",1).then((result) => {
      const eventsList = [];
      const markedDatesList = [];
      result.data.map((followup) => {
        if (followup.follow_up_date) {
          const eventDate = new Date(followup.follow_up_date +' UTC');
          markedDatesList.push(eventDate);
          eventsList.push({
            id: followup.id,
            date: eventDate,
            isCheckIn:followup.requires_checkin,
            name: followup.caller_first_name?followup.caller_first_name:'' + (followup.caller_last_name?" " + followup.caller_last_name:'')
          });
        }
      });

      getFollowUps("checkin",1).then((result) => { 
        result.data.map((followup) => {
          if (followup.follow_up_date) {
            const eventDate = new Date(followup.follow_up_date +' UTC');
            markedDatesList.push(eventDate);
            eventsList.push({
              id: followup.id,
              date: eventDate,
              isCheckIn:followup.requires_checkin,
              name: followup.caller_first_name?followup.caller_first_name:'' + (followup.caller_last_name?" " + followup.caller_last_name:'')
            });
          }
        });

      });

      setEvents(eventsList);
      setMarkedDates(markedDatesList);
    });
  }, []);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleTimeChange = (newTime) => {
    props.setUpdatedFollowUpDate(newTime);
  };

  const handleWeekJump = (numberOfWeeks) => {
    var currentDate = new Date(selectedDate); 
    currentDate.setDate( currentDate.getDate()+ numberOfWeeks * 7);
    setSelectedDate(currentDate);
 
  };
  const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 9; hour < 22; hour++) {
      const time = new Date(selectedDate);
      time.setHours(hour, 0, 0, 0);
      slots.push(time);
    }
    return slots;
  };
  //console.log(markedDates)


  // Helper to check if a date is marked
  const isMarked = (date) => {
    return markedDates.some(
      (markedDate) =>
        date.getFullYear() === markedDate.getFullYear() &&
        date.getMonth() === markedDate.getMonth() &&
        date.getDate() === markedDate.getDate()
    );
  };


  return (
    <Container className="p-3">
      <Title className="pb-3">Day Scheduler</Title>
      <div className="flex flex-col gap-4">
        <div className="grid-cols-2 grid  gap-5">
          <SchedulerContainer>
            <CalendarContainer>
              <EventColumn>
                <EventList>
                  {generateTimeSlots().map((time, index) => (
                    <div className="flex  border-b-2  border-b-moonstone " key={time.getHours()}>
                      <TimeSlot className="text-moonstone" key={index}>
                        {time.getHours()}:00
                      </TimeSlot>
                      <div className="flex flex-column w-full">
                        {events
                          .filter(
                            (event) =>
                              event.date.toDateString() ===
                                selectedDate.toDateString() &&
                              event.date.getHours() === time.getHours()
                          )
                          .map((event, eindex) => (
                            <EventItemContainer key={eindex}>
                              <EventItem
                                className={event.isCheckIn=== 1 ? "check-in" : eindex % 2 === 0 ? "even" : "odd"}
                                onClick={() => handleNav(event.id)}
                              >
                                <div className="capitalize">FOLLOW UP - {event.name.toLowerCase()}</div>
                              </EventItem>
                            </EventItemContainer>
                          ))}
                      </div>
                    </div>
                  ))}
                </EventList>
              </EventColumn>
            </CalendarContainer>
          </SchedulerContainer>
        <CalendarWrapper>
        <Calendar
          onChange={handleDateChange}
          minDate={props.MinDate ? new Date() : null}
          value={selectedDate?selectedDate:''}
          tileDisabled={({ date, view }) => view === "month" && isWeekend(date)}
          calendarType="gregory"
          className="justify-self-center"
          tileClassName={({ date, view }) =>
            view === 'month' && isMarked(date) ? 'highlight' : null
          }
        />
        </CalendarWrapper>
        </div>
        {props.showTimeSlots && (
          <div className="flex flex-col gap-5 justify-self-center">
             <Button onClick={()=> handleWeekJump (6)}> Six Weeks follow Up</Button>
             <TimeSlotPicker
              selectedDate={selectedDate}
              handleTimeChange={handleTimeChange}
            />
           
          </div>

        )}
      </div>
    </Container>
  );
};

export default Scheduler;

const CalendarWrapper = styled.div`


  /* Highlighted dates styling */
  .highlight {
    background: #d9eef0;
    color: #000;
    border-radius: 50%;
  }


`;

const Container = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const Title = styled.h1`
  color: #00323b;
  font-family: "Space Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 4.5px;
`;


const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const SchedulerContainer = styled.div`
  display: flex;
`;

const CalendarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const TimeSlot = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
`;

const EventColumn = styled.div`
  flex: 3;
  padding: 10px;
`;

const EventList = styled.div`
  position: relative;
`;

const EventItemContainer = styled.div`
  position: relative;
  width:100%;
`;

const EventItem = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
  padding: 9px 19px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor:pointer;

  font-family: "Futura PT";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 3.5px;
  &.check-in {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #CF3748;
    background: #fff;
    color: #CF3748;
    &:before {
      background-color: #CF3748;
    }
  }
  &.odd {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #2db3c0;
    background: #fff;
    color: #2db3c0;
    &:before {
      background-color: #2db3c0;
    }
  }
  &.even {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #ff7234;
    background: #fff;
    color: #ff7234;
    &:before {
      background-color: #ff7234;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    width: 12px;
    height: 24px;
    transform: translateY(-50%);
  }
`;


