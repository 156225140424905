import { Device } from "@twilio/voice-sdk";
export const makeCall=async (phoneNumber, setIsOnCall, call_detail,pastorInfo) => {
  
    var call, device,token;
    setIsOnCall(true);
    try {
      console.log("Requesting Access Token...");
      var options = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
        }}
       // console.log('auth :',process.env.REACT_APP_API_AUTHORIZATION,'url: ',process.env.REACT_APP_API_URL +"/token")
        
        options.body = JSON.stringify({'username':pastorInfo.fullName.replace(" ", '_')});
      const response = await fetch(
        process.env.REACT_APP_API_URL +"/token",
        options
      );
      const data = await response.json();
      token= data.token;
     // console.log("Got a token.");

     // console.log("Initializing device",token);

      if (token) {
        
        device = await new Device(token, {
          logLevel: 2,
          // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
          // providing better audio quality in restrained network conditions.
          debug: false,
          codecPreferences: ["opus", "pcmu"],
        });

        // Listen for Twilio.Device states
       
        device.on("registered", function () {
          console.log("Twilio.Device Ready to make and receive calls!");
        });

        device.on("error", function (error) {
          console.log("Twilio.Device Error: " + error.message);
        });
        device.on('incoming', function(conn) {
          console.log('Incoming connection from:', conn.parameters.From);
          conn.accept(); // Automatically accept incoming calls (or provide UI to accept)
      });


        // Device must be registered in order to receive incoming calls
        device.register();
    
      }
    } catch (error) {
      console.log(`Error: ${error.message}`);
    }
  
//setup call parameters
    var params = {
      // get the phone number to call
      To: phoneNumber,
      client: pastorInfo.fullName.replace(" ", '_')
    };

    if (device) {
      console.log(`Attempting to call ${params.To} ...`);

      // Twilio.Device.connect() returns a Call object
      call = await device.connect({ params });

      // add listeners to the Call
      call.on("initiated",  () => { 
        setIsOnCall(true);
        console.log("initiated");

  
      });
      // "accepted" means the call has finished connecting and the state is now "open"
      call.on("accept", () => {
        console.log("accept",call,pastorInfo);
        setIsOnCall(true);
        //intial record to be updated with status changes
        var insertionTime=new Date().toISOString().slice(0, 19).replace("T", " ");
       var call_stats={
        call_sid:call.parameters.CallSid,
        task_sid : call_detail.task_sid, 
        user_number:call_detail.user_number, 
        twilio_number:call_detail.twilio_number,
        email: pastorInfo.email,
        direction:'outbound',
        agent_full_name:pastorInfo.fullName,
        call_start_time:insertionTime,
       // call_end_time:insertionTime,
        duration:0
        }

        //inserting call_stat record in the 
      const url = process.env.REACT_APP_API_URL + "/call_stat";
      var options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
          "Accept": 'application/json',
          
        },
        body: JSON.stringify(call_stats),
      };
       fetch(url, options);

    
      });

      call.on("disconnect", (call) => {
        console.log("disconnected",call);  
       // console.log(call)
        device.destroy();
        setIsOnCall(false);
      });
      call.on("cancel", () => {
        console.log("cancel");
      });
  
    } else {
      console.log("Unable to make call.");
      setIsOnCall(false);
    }
    return({'device':device,'call':call, 'callDetails' :call_detail });
  }
 
