import React, { useState, createContext } from "react";
import { Fetch } from "../hooks/use-fetch-hook";
import { showSuccessMessage } from "../helpers/useToast";
import { format } from 'date-fns';

export const PastoralContext = createContext(null);

export const PastoralProvider = ({ children }) => {
  const [followUps, setFollowUps] = useState(null);
  const [followUp, setFollowUp] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isOnCall, setIsOnCall] = useState(false);
  const [callSettings, setCallSettings] = useState([]);
  const [activeTopBar, setActiveTopBar] = useState(null);
  const [activeSideBar, setActiveSideBar] = useState(1);
  const [isMute, setIsMute] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [sortBy, setSortBy] =useState();
  const [sortOrder, setSortOrder] =useState();

  const [filter, setFilter] = useState("");

  const setSort =(field)=>
  {
     
      switch(field)
      {
        case "newDate":
          setSortBy('created_at');
          break;
        case 'followupDate':
            setSortBy('follow_up_date');
          break;
        case 'followupType':
            setSortBy('follow_up_type');
            break;
        case 'partner':
              setSortBy('agent_name');
              break;
        default:
                setSortBy('created_at');
        
          
      }   
      sortOrder ==='desc'?
          setSortOrder('asc'):setSortOrder('desc');
  }

  const getFollowUps = async (status, page) => {
     var url = process.env.REACT_APP_API_URL + `/follow_ups?status=`;
     if(status === 'checkin'){
      url +='completed&checkin=1';
    }
     else
    url += status;
    //pagination
    if (page) url += "&page=" + page;

    //sorting
    if (sortBy && sortOrder) 
      {
        url += "& sort_by="+sortBy+"& sort_order="+sortOrder
      }
    //searching
    if (filter && searchValue) 
      {
        var filterParam="";
        switch(filter)
        {
          case 'agent_name':
          case 'user_number':
          case 'caller_name':
          case 'follow_up_type':
            filterParam = "&" + filter +'='+encodeURIComponent(searchValue);
            break;
          case 'date_range':
            filterParam = "&" + filter +'='+format(searchValue[0], 'yyyy-MM-dd')+','+format(searchValue[1], 'yyyy-MM-dd');

            break;
         
          default:
            filterParam = "&" + filter +'='+searchValue;
        }
        url +=filterParam ;

      }

    var options = {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };
    try {
      const result = await fetch(url, options);
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
      return await result.json();
    } catch (err) {
      return err.message;
    }
  };

  const getResources = async (status, page) => {
    var url =
      process.env.REACT_APP_API_URL + `/resource/physical?status=${status}`;
      if (page) url += "&page=" + page;

        //searching
    if (filter && searchValue) 
      {
        var filterParam="";
        switch(filter)
        {
          case 'agent_name':
          case 'caller_name':
          case 'user_number':
            filterParam = "&" + filter +'='+encodeURIComponent(searchValue);
            break;
        
          default:
            filterParam = "&" + filter +'='+searchValue;
        }
        url +=filterParam ;

      }

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };

    const result = await fetch(url, options);
    return await result.json();
  };

  const updateResource = async (id, sent_by) => {
    const url = process.env.REACT_APP_API_URL + "/prayerresource/updatePhyResc";

    var options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
      body: JSON.stringify({ id, sent_by }),
    };
    const result = await Fetch(url, options);
    return await result.json();
  };
  

  const getFollowUp = async (id) => {
    const url = process.env.REACT_APP_API_URL + `/call_detail?id=${id}`;
    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };

    const result = await fetch(url, options);
    return await result.json();
  };

  const updateFollowUp = async (requestObj) => {
    const url = process.env.REACT_APP_API_URL + "/call_detail";
    var options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
        "Accept": 'application/json',
        
      },
      body: JSON.stringify(requestObj),
    };
     await Fetch(url, options);
    setIsUpdated(true);
    showSuccessMessage(
      requestObj.status === 2
        ? "Follow Up Closed Successfully"
        : "Follow Up Updated Successfully"
    );
  };

  const getCallLogs = async (requestObj) => {
    var url =
      process.env.REACT_APP_API_URL +
      `/call_stats?direction=outgoing${requestObj.task_sid? '&taskSid='+requestObj.task_sid:''}${requestObj.agent_name?'&agent_name='+requestObj.agent_name:''}${requestObj.page? '&page='+requestObj.page:''}`;
         //searching
    if (filter && searchValue) 
      {
        var filterParam="";
        switch(filter)
        {     
          case 'user_number':
            filterParam = "&caller_number="+encodeURIComponent(searchValue);
            break;
          case 'date_range':
            filterParam = "&start_date="+format(searchValue[0], 'yyyy-MM-dd')+'&end_date='+format(searchValue[1], 'yyyy-MM-dd');
            break;
         
          default:
            filterParam = "&" + filter +'='+searchValue;
        }
        url +=filterParam ;

      }

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
        "Accept": 'application/json',
      },
    };
    const result = await fetch(url, options);

    return await result.json();
  };

  const getRepeatCalls = async (usernumber) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/call_stats?caller_number=${encodeURIComponent(usernumber)}&direction=incoming&limit=3`;
    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
        "Accept": 'application/json',
      },
    };
    const result = await fetch(url, options);

    return await result.json();
  };

  const getCallStats = async (requestObj) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/call_stats?called_number=${requestObj.called_number}`;

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
        "Accept": 'application/json',
      },
    };
    const result = await fetch(url, options);

    return await result.json();
  };

  const getReportsData = async (requestObj) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/reports`;

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
        "Accept": 'application/json',
      },
    };
    const result = await fetch(url, options);

    return await result.json();
  };
  return (
    <PastoralContext.Provider
      value={{
        followUps,
        followUp,
        getFollowUp,
        updateFollowUp,
        setFollowUp,
        getFollowUps,
        isOnCall,
        setIsOnCall,
        isUpdated,
        setIsUpdated,
        updateResource,
        setFollowUps,
        getCallLogs,
        getResources,
        getCallStats,
        searchValue,
        setSearchValue,
        callSettings,
        setCallSettings,
        isMute,
        setIsMute,
        activeTopBar,
        setActiveTopBar,
        activeSideBar,
        setActiveSideBar,
        seconds,
        setSeconds,
        filter, 
        setFilter,
        getReportsData,
        getRepeatCalls,
        setSort,
        sortOrder
      }}
    >
      {children}
    </PastoralContext.Provider>
  );
};
