import React, { useContext, useState,useEffect } from "react";
import {
  callTypes,
  resources,
  ageRanges,
  followUpTypes,
  digitalDelivery,
} from "./../../../assets/data";
import { ToastContainer } from "react-toastify";
import { CallFormContext } from "./../../../contexts/CallFormContext";
import CallTips from "./CallTips";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { ProfileContext } from "./../../../contexts/ProfileContext";
import { showErrorMessage } from "../../../helpers/useToast";

//CALL FORM IS THE MAIN PAGE FOR MOST OF THE FUNCTIONALITY OF THIS APPLICATION
//The form where prayer partner fills up information when on calls
const CallForm = (props) => {
  const { organization ,worker} = useContext(ProfileContext);
  const {formObj, handleCheckboxChange,handleOnSubmit,clearForm} = useContext(CallFormContext);
  const [modalShow, setModalShow] = useState(false);

  const handlePhoneNumberChange = (e) => {
    let inputValue = e.target.value;

    // Ensure the value always starts with a '+'
    if (!inputValue.startsWith('+')) {
      inputValue = '+' + inputValue.replace(/[^0-9]/g, ''); // Remove any non-numeric characters
    } else {
      inputValue = '+' + inputValue.slice(1).replace(/[^0-9]/g, ''); // Only allow numbers after the +
    }

    formObj.setUserNumber(inputValue);
  };

  useEffect(()=>{
  clearForm();
  formObj.setTwilioNumber(
    (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test" && organization)
      ? organization.assignedNumber
      : process.env.REACT_APP_TWILIO_NUMBER
  ); 
  formObj.setUserNumber('+');
  formObj.setSelectedDisposition('Pastoral Call - FOLLOW UP');
  formObj.setCallNotes('Created from Pastoral dashboard');
  formObj.setAgentName(worker.fullName);
  formObj.setSentBy(worker.fullName);
  formObj.setTaskSID(crypto.randomUUID());
  },[props]);


 
  return (
    <Wrapper className="row mb-5 mt-5">
      <ToastContainer />
      <>
        <CallTips show={modalShow} onHide={() => setModalShow(false)} />
      </>

      <div className=" col-12 mb-5 px-5 br-20">
        <Header className="p-3 mt-4">{"NEW MINISTRY FOLLOW-UP"}</Header>
        <Form
          onSubmit={handleOnSubmit}
          id="callForm"
          className="flex flex-column"
          autoComplete="off"
        >
          <div className="w-full">
            <CallerInfoWrapper className="p-4">
              <div className="row form-group mb-2">
                <div className="col">
                  <label htmlFor="fullName">
                    <h6>NAME</h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    onChange={(e) => formObj.setFullName(e.target.value)}
                    value={formObj.fullName}
                  />
                </div>
                <div className="col">
                  <label htmlFor="phoneNumber">
                    <h6> PHONE NUMBER<Redish>*</Redish></h6>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNumber"
                    onChange={(e) => handlePhoneNumberChange(e)}
                    value={formObj.userNumber}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col form-check-inline">
                  <label htmlFor="ageRange">
                    <h6>
                      AGE RANGE<Redish>*</Redish>
                    </h6>
                  </label>
                  <select
                    className="form-select mb-2"
                    aria-label="Age Range List"
                    id="ageRangeList"
                    value={formObj.selectedAgeRange}
                    onChange={(e) =>
                      formObj.setSelectedAgeRange(e.target.value)
                    }
                  >
                    <option value="">Choose Age Range</option>
                    {ageRanges.map((ageRange) => {
                      return (
                        <option
                          key={ageRange.id + ageRange.range}
                          value={ageRange.range}
                        >
                          {ageRange.range}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="gender">
                    <h6>
                      GENDER<Redish>*</Redish>
                    </h6>
                  </label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="gender"
                      type="radio"
                      checked={formObj.selectedGender === "male"}
                      value="male"
                      onChange={(e) =>
                        formObj.setSelectedGender(e.target.value)
                      }
                    />
                    <label className="form-check-label" htmlFor="maleGender">
                      Male
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="gender"
                      type="radio"
                      checked={formObj.selectedGender === "female"}
                      value="female"
                      onChange={(e) =>
                        formObj.setSelectedGender(e.target.value)
                      }
                    />
                    <label className="form-check-label" htmlFor="femaleGender">
                      Female
                    </label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      name="gender"
                      type="radio"
                      checked={formObj.selectedGender === "prefer not to say"}
                      value="prefer not to say"
                      onChange={(e) =>
                        formObj.setSelectedGender(e.target.value)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notKnownGender"
                    >
                      Prefer not to say
                    </label>
                  </div>
                </div>
              </div>
            </CallerInfoWrapper>
            <CallTypeWrapper className="p-4 pt-3 flex flex-row">
              <CallTypes className="mt-4 mb-4">
                <h3 className="mb-4">
                  Call Type<Redish>*</Redish>
                </h3>
                <div className="overflow-visible">
                  {callTypes.map((callType) => {
                    return (
                      <div
                        key={callType.id + callType.type}
                        className={"form-check mb-1"}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={callType.id + callType.type}
                          checked={formObj.selectedCallTypes
                            .split(", ")
                            .some((item) => {
                              return item === callType.type;
                            })}
                          onChange={(e) => {
                            handleCheckboxChange(
                              e,
                              formObj.setSelectedCallTypes,
                              formObj.selectedCallTypes,
                              callType.type
                            );
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={callType.id + callType.type}
                        >
                          {callType.type}
                          {callType.children && <Blueish> {"> "}</Blueish>}
                        </label>
                        {callType.children &&
                          callType.children.length > 0 &&
                          (formObj.selectedCallTypes
                            .split(",")
                            .includes(callType.type) ||
                            formObj.selectedCallTypes.includes(
                              callType.type
                            )) && (
                            <div
                              style={{ marginLeft: "20px", margin: "5px" }}
                              className=""
                            >
                              {callType.children.map((child, childIndex) => (
                                <div
                                  className="ml-3"
                                  key={`${callType.id}-${childIndex}`}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={(e) => {
                                      handleCheckboxChange(
                                        e,
                                        formObj.setSelectedCallTypes,
                                        formObj.selectedCallTypes,
                                        callType.type + " - " + child
                                      );
                                    }}
                                  />
                                  <label
                                    key={`${callType.id}-${childIndex}`}
                                    className="block"
                                  >
                                    {child}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              </CallTypes>

              <CallNotes className="px-5 mt-4 p-2">
                <h3 className="mb-4">Pastoral Notes</h3>

                <textarea
                  className="form-control"
                  id="callNotes"
                  rows="15"
                  onChange={(e) => formObj.setPastoralNotes(e.target.value)}
                  value={formObj.pastoralNotes}
                ></textarea>
              </CallNotes>
            </CallTypeWrapper>

            <FollowUpWrapper className="mt-4 p-4 bg-[#FAF1EF]">
              <h3>
                Follow-Up Information
              </h3>
                <FolowUpTypeWrapper className="p-4 mt-4">
                  <FolowUpTypes className=" row  mt-4 mb-4">
                    <h4 className="mb-4">
                      Follow Up Type<Redish>*</Redish>
                    </h4>
                    {followUpTypes.map((followUpType) => {
                      return (
                        <div
                          key={followUpType.id}
                          className={
                            "col-md-6 form-check mb-1 order-col-" +
                            followUpType.id
                          }
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={followUpType.id + followUpType.type}
                            checked={formObj.selectedFollowUpTypes
                              .split(", ")
                              .some((item) => {
                                return item === followUpType.type;
                              })}
                            onChange={(e) => {
                              handleCheckboxChange(
                                e,
                                formObj.setSelectedFollowUpTypes,
                                formObj.selectedFollowUpTypes,
                                followUpType.type
                              );
                             if (followUpType.type === 'Suicide Call' && e.target.checked){
                              formObj.setIsSuicideCall(e.target.checked);
                            }else{
                              formObj.setIsSuicideCall(false);
                            }

                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={followUpType.id + followUpType.type}
                          >
                            {followUpType.type}
                          </label>
                        </div>
                      );
                    })}
                  </FolowUpTypes>
                </FolowUpTypeWrapper>
              {/* )} */}
            </FollowUpWrapper>
            <div className="row form-group mb-2 mt-2">
                    <div className="col form-check ">
                      <h6>SEND RESOURCES</h6>

                      <div className="mb-4">
                        <div
                          key="Resources"
                          className="form-check form-check-inline"
                        >
                          <input
                            className="form-check-input"
                            name="resourceOption"
                            type="radio"
                            checked={formObj.sendResource ==='true'}
                            value='true'
                            onChange={(e) => formObj.setSendResource(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Resources"
                          >
                            Resources
                          </label>
                        </div>
                        <div
                          key="noResources"
                          className="form-check form-check-inline"
                        >
                          <input
                            className="form-check-input"
                            name="resourceOption"
                            type="radio"
                            checked={formObj.sendResource==='false'}
                            value='false'
                            onChange={(e) => formObj.setSendResource(e.target.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="noResources"
                          >
                            No Resources
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

          {formObj.sendResource ==='true'  &&  <div className="mt-4 p-4 bg-[#F3F9FA]">
              <h6>SEND RESOURCES</h6>

                <DigitalResources className="col m-3">
                  <div className="row">
                    <h6>HOW IS IT BEING SENT? </h6>
                    <SendViaWrapper className="rounded col-6 mb-4 p-2 m-3">
                      <p>Send Via:</p>
                      {digitalDelivery.map((item) => {
                        return (
                          <div
                            key={item.id + item.value}
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              name="sendVia"
                              type="radio"
                              checked={
                                formObj.selectedDigitalDelivery === item.value
                              }
                              value={item.value}
                              onChange={(e) =>
                                formObj.setSelectedDigitalDelivery(
                                  e.target.value
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={item.value + "Delivery"}
                            >
                              {item.text}
                            </label>
                          </div>
                        );
                      })}
                    </SendViaWrapper>
                    {formObj.selectedDigitalDelivery === "email" && (
                      <div className="col">
                        <label htmlFor="emailAddress">
                          <h6>EMAIL</h6>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailAddress"
                          onChange={(e) =>
                            formObj.setEmailAddress(e.target.value)
                          }
                          value={formObj.emailAddress}
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          Format: name@domain.something
                        </small>
                      </div>
                    )}
                    {formObj.selectedDigitalDelivery === "sms" && (
                      <div className="col">
                        <label htmlFor="phoneNumber">
                          <h6> PHONE NUMBER</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneNumber"
                          onChange={(e) =>
                            formObj.setUserNumber(e.target.value)
                          }
                          value={formObj.userNumber}
                        />
                      </div>
                    )}
                  </div>
                  <div className="relative grid grid-cols-2 mt-3">
                    <h6>DIGITAL RESOURCES</h6>
                    {resources
                      .filter((resource) => resource.type === "digital")
                      .map((resource) => {
                        return (
                          <div
                            key={resource.id + resource.resource}
                            className="row  form-check mb-2"
                          >
                            <div className="col">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={resource.id + resource.resource}
                                checked={formObj.selectedDigitalResources?.split(", ")
                                  .some((item) => {
                                    return item === resource.resource;
                                  })}
                                onChange={(e) => {
                                 
                                  var digitalArray =formObj.selectedDigitalResources?formObj.selectedDigitalResources.split(", "):[];
                                 ( (formObj.selectedDigitalDelivery === "sms" && digitalArray.length > 2 && !digitalArray.includes(resource.resource)))?  
                                 showErrorMessage("For SMS you are limited to three digital resources")
                                 :
                                  handleCheckboxChange(
                                    e,
                                    formObj.setSelectedDigitalResources,
                                    formObj.selectedDigitalResources,
                                    resource.resource
                                  )

                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={resource.id + resource.resource}
                              >
                                {resource.resource}
                              </label>
                              <span className="hidden">
                                {resource.description}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </DigitalResources>
             
                  <PhysicalResources className="m-3 col">
                    <div>
                      <h6>PHYSICAL RESOURCES</h6>
                      {resources
                        .filter((resource) => resource.type === "physical")
                        .map((resource) => {
                          return (
                            <div
                              key={resource.id + resource.resource}
                              className="row form-check mb-2"
                            >
                              <div className="col">
                                <input
                                  type="checkbox"
                                  className="form-check-input physical-resources"
                                  id={resource.id + resource.resource}
                                  checked={formObj.selectedPhysicalResources
                                    ?.split(", ")
                                    .some((item) => {
                                      return item === resource.resource;
                                    })}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      e,
                                      formObj.setSelectedPhysicalResources,
                                      formObj.selectedPhysicalResources,
                                      resource.resource
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={resource.id + resource.resource}
                                >
                                  {resource.resource}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="rounded p-3"
                      style={{ border: "1px solid #2DB3C0" }}
                    >
                      <h6 className="mb-3">
                        TO BE SENT VIA MAIL<Redish>*</Redish>
                      </h6>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="mailingAddress">
                            <h6> MAILING ADDRESS</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            onChange={(e) => formObj.setAddress(e.target.value)}
                            value={formObj.address}
                          />
                        </div>
                      </div>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="city">
                            <h6>CITY</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            onChange={(e) =>
                              formObj.setUserCity(e.target.value)
                            }
                            value={formObj.userCity}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="province">
                            <h6>PROVINCE / STATE</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="province"
                            onChange={(e) =>
                              formObj.setUserStateProv(e.target.value)
                            }
                            value={formObj.userStateProv}
                          />
                        </div>
                      </div>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="postalCode">
                            <h6>POSTAL CODE / ZIP</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postalCode"
                            onChange={(e) =>
                              formObj.setUserZipPostal(e.target.value)
                            }
                            value={formObj.userZipPostal}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="country">
                            <h6>COUNTRY</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="country"
                            onChange={(e) =>
                              formObj.setUserCountry(e.target.value)
                            }
                            value={formObj.userCountry}
                          />
                        </div>
                      </div>
                      <div className="row form-check mb-3">
                        <div className="col">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isHouse"
                            checked={formObj.isHouse}
                            onChange={(e) =>
                              formObj.setIsHouse(e.target.checked)
                            }
                          />
                          <label className="form-check-label" htmlFor="isHouse">
                            Address is an apartment?
                            {formObj.isHouse && (
                              <span>
                                {" "}
                                (
                                <small
                                  id="mailingAddress"
                                  className="form-text text-muted"
                                >
                                  Request unit number
                                </small>
                                )
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </PhysicalResources>
                  
            </div>     }
          </div>
          <ButtonWrapper className="m-4 p-4 mb-5 flex flex-column">
            <FormButton
              type="submit"
              className="btn-lg m-2  z-0 bg-moonstone hover:bg-opacity-70"
              value ={1}
            >
              SAVE NEW FOLLOW UP
            </FormButton>
          
            <FormButton className=" btn-lg m-2  z-0 bg-ikura hover:bg-opacity-70" value ={2}>
              CLOSE FOLLOW-UP 
            </FormButton>
          </ButtonWrapper>
        </Form>
      </div>
    </Wrapper>
  );
};

export default CallForm;

const Wrapper = styled.div`
  font-family: "Space Grotesk";
`;

const Header = styled.span`
  background-color: #ff7234;
  color: white;
  display: block;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const Form = styled.form``;

const CallerInfoWrapper = styled.div``;

const CallTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f9fa;
`;

const CallTypes = styled.div`
  width: 30%;
`;

const FolowUpTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f9fa;
`;

const FolowUpTypes = styled.div`
  align-self: center;
`;

const CallNotes = styled.div`
  border-left: 3px solid #adcfd4;
  width: 60%;
`;
const FollowUpWrapper = styled.div``;

const DigitalResources = styled.div``;

const SendViaWrapper = styled.div`
  border: 1px solid #2db3c0;
`;
const PhysicalResources = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  button {
    box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out 300ms;
  }
`;
const FormButton = styled.button`
  color: white;
  border-radius: 8px;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
`;
const Redish = styled.span`
  color: #ec3310 !important;
`;
export const Blueish = styled.span`
  color: #178b96 !important;
`;
