import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import { Spinner } from "react-activity";
import { ToastContainer } from "react-toastify";
import { PiLessThan } from "react-icons/pi";
import styled from "styled-components";
import "react-activity/dist/library.css";
import "react-toastify/dist/ReactToastify.css";
import { CallFormContext } from "./../../../contexts/CallFormContext";
import { camelCase } from "lodash";
import CallHistory from "./../pages/call-history";
import { RecordingPlayBack } from "./../components/recordingPlayback";
import { makeCall } from "../../../helpers/makeCall";
import {
  callTypes,
  resources,
  ageRanges,
  followUpTypes,
  digitalDelivery,
} from "./../../../assets/data";
import { Icon } from "@mui/material";
import FollowupScheduler from "../components/modals/followupScheduler";
import { ProfileContext } from "./../../../contexts/ProfileContext";
import { showErrorMessage } from "./../../../helpers/useToast";
import LocationSelector from "../components/locationSelector";
import PastoralCalls from "./pastoralCalls";
import ConfirmDialog from "./modals/ConfirmDialog";

export const ViewFollowUp = (props) => {
  const { worker, organization } = useContext(ProfileContext);
  const id = props.followUpID;
  const { handleCheckboxChange, formObj, handleSendingResources, clearForm } =
    useContext(CallFormContext);
  const {
    setFollowUp,
    followUp,
    updateFollowUp,
    setIsOnCall,
    setCallSettings,
    isOnCall,
  } = useContext(PastoralContext);
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [updatedEmailAddress, setUpdatedEmailAddress] = useState("");
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
  const [updatedMailingAddress, setUpdatedMailingAddress] = useState("");
  const [updatedPostalCode, setUpdatedPostalCode] = useState("");
  const [updatedCity, setUpdatedCity] = useState("");
  const [updatedProvince, setUpdatedProvince] = useState("");
  const [updatedCountry, setUpdatedCountry] = useState("");
  const [updatedGender, setUpdatedGender] = useState("");
  const [updatedCallTypes, setUpdatedCalltypes] = useState("");
  const [updatedFollowUpTypes, setUpdatedFollowUpTypes] = useState("");
  const [updatedAgeRange, setUpdatedAgeRange] = useState("");
  const [updatedNotes, setUpdatedNotes] = useState("");
  const [updatePastorNotes, setUpdatePastorNotes] = useState("");
  const [updateTaskSid, setUpdateTaskSid] = useState("");
  const [updatedFollowUpDate, setUpdatedFollowUpDate] = useState(null);
  const [showScheduler, setShowScheduler] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasCheckin, setHasCheckin] = useState(false);
  const [intialStatus, setIntialStatus] = useState("");
  const [device, setDevice] = useState();
  const [call, setCall] = useState();
  const navigate = useNavigate();

  const changeContent = () => {
    navigate(-1);
  };
  
  const handleConfirmation= (e,result) => {
    setHasCheckin(result);
  
    if (result) {
      setIsDialogOpen(false);
      setShowScheduler(true);
    } else {
      updateFollowUpStatus(e, 2,result);
    }
  }

  const scheduleFollowUp = (e, status) => {
    e.preventDefault();
    setShowScheduler(true);
  };

  const closeFollowUp = (e, status) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  const handleMakeCall = async (followup) => {
    makeCall(
      followup.call_details.user_number,
      setIsOnCall,
      followup.call_details,
      worker
    ).then((res) => {
      setCallSettings(res);
      setCall(res.call);
      setDevice(res.device);
    });
  };

  const updateFollowUpStatus = (e, status,checkin=false ) => {
    if (!updatedGender) {
      showErrorMessage("Please select a Gender.");
      return;
    }
    if (!updatedFollowUpTypes) {
      showErrorMessage("Please select a least one follow up type.");
      return;
    }

    const updatedObj = {
      id,
      status,
      user_number: updatedPhoneNumber,
      call_type: updatedCallTypes,
      notes: updatedNotes,
      caller_first_name: updatedFirstName,
      caller_last_name: updatedLastName,
      caller_email: updatedEmailAddress,
      age_range: updatedAgeRange,
      gender: updatedGender,
      address: updatedMailingAddress,
      city: updatedCity,
      province: updatedProvince,
      postal_zip: updatedPostalCode,
      country: updatedCountry,
      pastoral_notes: updatePastorNotes,
      follow_up_type: updatedFollowUpTypes,
      requires_checkin:  +checkin
    };

    if (
      updatedFollowUpDate &&
      !isNaN(new Date(updatedFollowUpDate).getTime())
    ) {
      updatedObj.follow_up_date = new Date(updatedFollowUpDate)
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
    }
    if (status === 2)
      updatedObj.closing_date = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

    updateFollowUp(updatedObj).then(() => {
      if (formObj.sendResource === "true") handleSendingResources();
    });
    
    navigate('../followups/'+(intialStatus === '1'?'new':intialStatus === '3'?'ongoing':'completed'));
  };

  useEffect(() => {
    clearForm();
    const result = props.followUp;
    result.call_details.follow_up_date ??
      setUpdatedFollowUpDate(
        new Date(result.call_details.follow_up_date + " UTC")
      );
    setIntialStatus(result.call_details.status);
    setUpdatedFirstName(result.call_details.caller_first_name);
    setUpdatedLastName(result.call_details.caller_last_name || "");
    setUpdatedPhoneNumber(result.call_details.user_number || "");
    setUpdatedEmailAddress(result.call_details.caller_email || "");
    setUpdatedMailingAddress(result.call_details.address || "");
    setUpdatedCalltypes(result.call_details.call_type || "");
    setUpdatedFollowUpTypes(result.call_details.follow_up_type || "");
    setUpdatedPostalCode(result.call_details.postal_zip || "");
    setUpdatedProvince(result.call_details.province || "");
    setUpdatedCity(result.call_details.city || "");
    setUpdatedCountry(result.call_details.country || "");
    setUpdatedGender(result.call_details.gender || "");
    setUpdatedNotes(result.call_details.notes || "");
    setUpdatedAgeRange(result.call_details.age_range || "");
    setUpdatePastorNotes(result.call_details.pastoral_notes || "");
    formObj.setFullName(
      result.call_details.caller_first_name +
        " " +
        result.call_details.caller_last_name
    );
    setFollowUp(result);
    setHasCheckin(!!result.call_details.requires_checkin);
    formObj.setUserNumber(result.call_details.user_number);
    formObj.setTaskSID(result.call_details.task_sid);
    setUpdateTaskSid(result.call_details.task_sid);
    formObj.setSentBy(worker.fullName);
    formObj.setTwilioNumber(
      process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test" && organization
        ? organization.assignedNumber
        : process.env.REACT_APP_TWILIO_NUMBER
    );
  }, [id]);

  return (
    <div>
      <FollowupScheduler
        setShow={setShowScheduler}
        show={showScheduler}
        selectedDate={updatedFollowUpDate}
        setUpdatedFollowUpDate={setUpdatedFollowUpDate}
        updateRecord={(e) => {
          updateFollowUpStatus(e, hasCheckin?2:3,hasCheckin);
        }}
        onHide={(e) => {
          setShowScheduler(false);
        }}
      />
      <ConfirmDialog
      show={isDialogOpen}
        onHide={()=>setIsDialogOpen(false)}
        title="Schedule check-in"
        message="Do you want to schedule a Wellness check?"
        onConfirm={handleConfirmation}
        
      />

      {followUp && !followUp.error ? (
        <div className="grid gap-4 grid-cols-1">
          {/* NAVIGATION BACK TO THE LIST PAGE */}
          <BackLinkContainer className="p-3 bg-transparent flex align-middle">
            <button
              onClick={() => {
                changeContent(
                  followUp.call_details.status === "1"
                    ? "NewFollowUp"
                    : followUp.call_details.status === "2"
                    ? "CompletedFollowUp"
                    : "OngoingFollowUp"
                );
              }}
              style={{ background: "none", border: "0" }}
            >
              <PiLessThan size={25} />

              <TableHeading className="text-metallicSeaweed2 flex align-self-center pl-3">
                BACK TO{" "}
                {followUp.call_details.status === "1"
                  ? "NEW"
                  : followUp.call_details.status === "2"
                  ? "COMPLETED"
                  : "ONGOING"}{" "}
                FOLLOW-UPS
              </TableHeading>
            </button>
          </BackLinkContainer>

          <ToastContainer />
          {/* TOP TABLE SECTION WITH TABULATED CALL DETAILS */}
          <Wrapper className=" mx-5 ">
            <Table>
              <thead>
                <TableRow>
                  <Th className="text-richBlack p-2">
                    {followUp.call_details.status === "1"
                      ? "Creation "
                      : followUp.call_details.status === "2" && followUp.call_details.requires_checkin ==="0"
                      ? "Closing "
                      : "Follow-Up "}
                    Date
                  </Th>
                  <Th className="text-richBlack"> Caller Name</Th>
                  <Th className="text-richBlack"> Phone Number</Th>
                  <Th className="text-richBlack"> Follow-Up Type </Th>
                  <Th className="text-richBlack"> Prayer Partner </Th>
                  <Th className="text-richBlack"> Status</Th>
                </TableRow>
              </thead>
              <tbody>
                <TableRow key={followUp.id} className="bg-azureish text-center">
                  <td className="p-3">
                    {followUp.call_details.status === "1"
                      ? new Date(
                          followUp.call_details.created_at +' UTC'
                        ).toLocaleDateString("en-US")
                      : followUp.call_details.follow_up_date &&
                        new Date(
                          followUp.call_details.follow_up_date +' UTC'
                        ).toLocaleDateString("en-US")}
                  </td>
                  <td>
                    {(updatedFirstName ? updatedFirstName : "") +
                      (updatedLastName ? " " + updatedLastName : "")}
                  </td>
                  <td>{updatedPhoneNumber}</td>

                  <TypeCell>
                    {updatedFollowUpTypes?.split(",").map((followUpType) => {
                      var name = followUpType
                        .replace(/\s/g, "")
                        .toLowerCase()
                        .split("/", 1)[0];
                      return (
                        <span key={name} className={name + " status p-1 m-2"}>
                          {followUpType}
                        </span>
                      );
                    })}
                  </TypeCell>
                  <td className="text-center">
                    {" "}
                    {followUp.call_details.agent_name}{" "}
                  </td>
                  <td className="text-center px-5">
                    <span
                      className={
                        (followUp.call_details.status === "1"
                          ? "new"
                          : followUp.call_details.status === "2"
                          ? "completed"
                          : "ongoing") + " text-decoration-none status"
                      }
                      style={{
                        color:
                          followUp.call_details.status === "1"
                            ? "#7FC008"
                            : followUp.call_details.status === "2"
                            ? "#DB303F"
                            : "#FF7234",
                      }}
                    >
                      {followUp.call_details.status === "1"
                        ? "New"
                        : followUp.call_details.status === "2"
                        ? "Completed"
                        : "Ongoing"}
                    </span>
                  </td>
                </TableRow>
              </tbody>
            </Table>
          </Wrapper>
          {/* CALL RECORDING SECTION */}
          <div className="flex justify-self-center">
            {followUp && (
              <RecordingPlayBack
                username={
                  updatedFirstName || updatedLastName
                    ? updatedFirstName + " " + updatedLastName
                    : "Anonymous Caller"
                }
                playback={followUp.call_stats}
                call_details={followUp.call_details}
              />
            )}
          </div>
          {/* CALL HISTORY SECTION  */}

          {updatedPhoneNumber && (
            <CallHistory userNumber={updatedPhoneNumber} />
          )}

          {/* follow up calls  */}
          {updateTaskSid && <PastoralCalls task_sid={updateTaskSid} />}

          {/* PASTORAL FORM */}
          <Form id="callForm" autoComplete="off">
            <div className="flex flex-row border-b-2 border-b-[#ADCFD4] justify-items-start mx-5">
              <CallerInfoWrapper className="border-r-2 border-r-[#ADCFD4]">
                <TableHeading className="flex justify-center bg-cream border-0 text-ikura p-2">
                  <span className="justify-self-center">
                    CALLER'S MINISTRY FORM
                  </span>
                </TableHeading>
                <CallTypeWrapper className="gap-4 bg-white px-4">
                  <div className=" flex flex-row mt-4">

                  <div className="col form-check-inline">
                         
                        <label htmlFor="firstname">
                          <Labelspan>FIRST NAME</Labelspan>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          onChange={(e) => setUpdatedFirstName(e.target.value)}
                          value={updatedFirstName}
                          disabled={followUp.call_details.status === "2"}
                        />
                      
                      </div>
                    <div className="col form-check-inline">
                  <label htmlFor="lastname">
                    <Labelspan>LAST NAME</Labelspan>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    onChange={(e) => setUpdatedLastName(e.target.value)}
                    value={updatedLastName}
                    disabled={followUp.call_details.status === "2"}
                  />
                
                    </div>
</div>
                    <div className=" flex flex-row mt-4">
                    <div className="col form-check-inline">
                      <label htmlFor="ageRange">
                        <Labelspan>AGE RANGE</Labelspan>
                      </label>
                      <select
                        className="form-select mb-2"
                        aria-label="Age Range List"
                        id="ageRangeList"
                        value={updatedAgeRange}
                        onChange={(e) => setUpdatedAgeRange(e.target.value)}
                        disabled={followUp.call_details.status === "2"}
                      >
                        <option value="">Choose Age Range</option>
                        {ageRanges.map((ageRange) => {
                          return (
                            <option
                              key={ageRange.id + ageRange.range}
                              value={ageRange.range}
                            >
                              {ageRange.range}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col">
                      <label htmlFor="gender">
                        <Labelspan>
                          GENDER<Redish>*</Redish>
                        </Labelspan>
                      </label>
                      <br />
                      {["male", "female", "prefer not to say"].map((gender) => (
                        <div
                          key={gender}
                          className="form-check form-check-inline"
                        >
                          <input
                            className="form-check-input"
                            name="gender"
                            type="radio"
                            checked={updatedGender === gender}
                            value={gender}
                            onChange={(e) => setUpdatedGender(e.target.value)}
                            disabled={followUp.call_details.status === "2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`${gender}Gender`}
                          >
                            {gender.charAt(0).toUpperCase() + gender.slice(1)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <CallTypes>
                    <Labelspan className="w-100 flex align-items-center mb-2">
                      CALL TYPE
                      <Icon>
                        <img
                          alt="edit"
                          src={require("./../../../assets/images/edit.png")}
                        />
                      </Icon>
                    </Labelspan>
                    {callTypes.map((callType, index) => {
                      const isParentTypeSelected = updatedCallTypes
                        .split(", ")
                        .includes(callType.type);

                      // Check if any children are selected, if the callType has children
                      const isChildTypeSelected = callType.children
                        ? callType.children.some((child) =>
                            updatedCallTypes
                              .split(", ")
                              .flatMap((part) => part.split(" - "))
                              .includes(child)
                          )
                        : false;

                      // Render the parent type if it's selected or any of its children are selected
                      return isParentTypeSelected || isChildTypeSelected ? (
                        <React.Fragment key={`${callType.id}${callType.type}`}>
                          {!isChildTypeSelected && (
                            <SpanContainer
                              className={`col-md-6 form-check mb-1 order-col-${callType.id}`}
                            >
                              <span
                                key={index}
                                className={
                                  camelCase(callType.type) + " p-1 m-2"
                                }
                                htmlFor={`${callType.id}${callType.type}`}
                              >
                                {callType.type}
                              </span>
                            </SpanContainer>
                          )}
                          {/* Render children if they exist and any of them are selected */}
                          {isChildTypeSelected &&
                            callType.children &&
                            callType.children.map((child, index) =>
                              updatedCallTypes
                                .split(", ")
                                .flatMap((part) => part.split(" - "))
                                .includes(child) ? (
                                <SpanContainer
                                  key={`${callType.id}${callType.type}${child}`}
                                  className={`col-md-6 form-check mb-1 order-col-${callType.id}`}
                                >
                                  <span
                                    key={index}
                                    className={
                                      camelCase(child.type) + " p-1 m-2"
                                    }
                                  >
                                    {callType.type + " - " + child}
                                  </span>
                                </SpanContainer>
                              ) : null
                            )}
                        </React.Fragment>
                      ) : null;
                    })}
                  </CallTypes>

                  <CallNotes className=" ">
                    <label htmlFor="callNotes">
                      <Labelspan>CALL NOTES</Labelspan>
                    </label>
                    <textarea
                      className="form-control"
                      id="callNotes"
                      rows="6"
                      onChange={(e) => setUpdatedNotes(e.target.value)}
                      value={updatedNotes}
                      disabled={followUp.call_details.status === "2"}
                    ></textarea>
                  </CallNotes>

                  <div>
                    <label className="row">
                      <Labelspan>PREVIOUS RESOURCES SENT</Labelspan>
                    </label>
                    <SpanContainer style={{ width: "35%" }}>
                      {followUp.call_resources.map((resource, index) => {
                        var name = resource.title;
                        return (
                          <span
                            key={index}
                            className={camelCase(name) + " p-1 m-2"}
                          >
                            {resource.title}
                          </span>
                        );
                      })}
                    </SpanContainer>
                  </div>
                </CallTypeWrapper>
              </CallerInfoWrapper>
              <PastorCareWrapper>
                <TableHeading className="bg-azureish flex justify-center w-full text-marina p-2">
                  {" "}
                  PASTORAL CARE
                </TableHeading>
                <CallTypeWrapper className="gap-4 bg-white px-4">
                  <CallNotes className="form-group mt-4 p-2">
                    <label htmlFor="pastoralNotes">
                      <Labelspan>PASTORAL NOTES</Labelspan>
                    </label>
                    <textarea
                      className="form-control"
                      id="pastoralNotes"
                      rows="6"
                      onChange={(e) => setUpdatePastorNotes(e.target.value)}
                      value={updatePastorNotes}
                      disabled={followUp.call_details.status === "2" && !followUp.call_details.requires_checkin}
                    ></textarea>
                  </CallNotes>
                  <FolowUpTypeWrapper className=" mt-4">
                    <FolowUpTypes className="row pl-4 ">
                      <Labelspan>
                        FOLLOW UP TYPE<Redish>*</Redish>
                      </Labelspan>
                      {followUpTypes.map((followUpType) => {
                        return (
                          <div
                            key={followUpType.id}
                            className={
                              "col-md-6 form-check mb-1 order-col-" +
                              followUpType.id
                            }
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={followUpType.id + followUpType.type}
                              checked={updatedFollowUpTypes
                                .split(", ")
                                .some((item) => {
                                  return item === followUpType.type;
                                })}
                              onChange={(e) => {
                                handleCheckboxChange(
                                  e,
                                  setUpdatedFollowUpTypes,
                                  updatedFollowUpTypes,
                                  followUpType.type
                                );
                              }}
                              disabled={followUp.call_details.status === "2"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={followUpType.id + followUpType.type}
                            >
                              {followUpType.type}
                            </label>
                          </div>
                        );
                      })}
                    </FolowUpTypes>
                  </FolowUpTypeWrapper>
                  <LocationSelector
                    updatedProvince={updatedProvince}
                    setUpdatedProvince={setUpdatedProvince}
                    updatedCountry={updatedCountry}
                    setUpdatedCountry={setUpdatedCountry}
                    isDisabled={followUp.call_details.status === "2"}
                  ></LocationSelector>
                  <div className="col">
                            <label htmlFor="emailAddress">
                            <Labelspan>EMAIL</Labelspan>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="emailAddress"
                              onChange={(e) =>{
                                setUpdatedEmailAddress(e.target.value)
                                formObj.setEmailAddress(e.target.value)
                              }
                              }
                              value={updatedEmailAddress}
                              disabled={followUp.call_details.status === "2"}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              Format: name@domain.something
                            </small>
                          </div>

                  {followUp.call_details.status !== "2" && (
                    <div className="row form-group mb-2 mt-2">
                      <div className="col form-check ">
                        <Labelspan>SEND RESOURCES</Labelspan>

                        <div className="mb-4">
                          <div
                            key="Resources"
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              name="resourceOption"
                              type="radio"
                              checked={formObj.sendResource === "true"}
                              value="true"
                              onChange={(e) =>
                                formObj.setSendResource(e.target.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Resources"
                            >
                              Resources
                            </label>
                          </div>
                          <div
                            key="noResources"
                            className="form-check form-check-inline"
                          >
                            <input
                              className="form-check-input"
                              name="resourceOption"
                              type="radio"
                              checked={formObj.sendResource === "false"}
                              value="false"
                              onChange={(e) =>
                                formObj.setSendResource(e.target.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="noResources"
                            >
                              No Resources
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </CallTypeWrapper>
              </PastorCareWrapper>
            </div>
            {/* SENDING RESOURCE DETAILS SECTION */}
            <div>
              <div className="m-5 p-4 grid grid-cols-2 bg-[#F3F9FA]">
                {formObj.sendResource !== "false" && (
                  <DigitalResources className="col m-3">
                    <div className="row w-full justify-center">
                      <Labelspan>DIGITAL RESOURCES</Labelspan>
                      <SendViaWrapper className="rounded col-6 mb-4 p-2 m-3">
                        <Labelspan>TO BE SENT VIA EMAIL OR SMS</Labelspan>
                        <div>
                          {digitalDelivery.map((item) => {
                            return (
                              <div
                                key={item.id + item.value}
                                className="form-check form-check-inline"
                              >
                                <input
                                  className="form-check-input"
                                  name="sendVia"
                                  type="radio"
                                  checked={
                                    formObj.selectedDigitalDelivery ===
                                    item.value
                                  }
                                  value={item.value}
                                  onChange={(e) =>
                                    formObj.setSelectedDigitalDelivery(
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={item.value + "Delivery"}
                                >
                                  {item.text}
                                </label>
                              </div>
                            );
                          })}
                        </div>

                        {formObj.selectedDigitalDelivery === "email" && (
                          <div className="col">
                            <label htmlFor="emailAddress">
                              <h6>EMAIL</h6>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="emailAddress"
                              onChange={(e) =>
                                formObj.setEmailAddress(e.target.value)
                              }
                              value={formObj.emailAddress}
                            />
                            <small
                              id="emailHelp"
                              className="form-text text-muted"
                            >
                              Format: name@domain.something
                            </small>
                          </div>
                        )}
                        {formObj.selectedDigitalDelivery === "sms" && (
                          <div className="col">
                            <label htmlFor="phoneNumber">
                              <h6> PHONE NUMBER</h6>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              defaultValue={updatedPhoneNumber}
                              onChange={(e) =>
                                formObj.setUserNumber(e.target.value)
                              }
                              value={formObj.useNumber}
                            />
                          </div>
                        )}
                      </SendViaWrapper>
                    </div>
                    <div className="relative grid grid-cols-2 mt-3">
                      {resources
                        .filter((resource) => resource.type === "digital")
                        .map((resource) => {
                          return (
                            <div
                              key={resource.id + resource.resource}
                              className="row  form-check mb-2"
                            >
                              <div className="col">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={resource.id + resource.resource}
                                  checked={formObj.selectedDigitalResources
                                    .split(", ")
                                    .some((item) => {
                                      return item === resource.resource;
                                    })}
                                  onChange={(e) => {
                                    var digitalArray =
                                      formObj.selectedDigitalResources
                                        ? formObj.selectedDigitalResources.split(
                                            ", "
                                          )
                                        : [];
                                    formObj.selectedDigitalDelivery === "sms" &&
                                    digitalArray.length > 2 &&
                                    !digitalArray.includes(resource.resource)
                                      ? showErrorMessage(
                                          "For SMS you are limited to three digital resources"
                                        )
                                      : handleCheckboxChange(
                                          e,
                                          formObj.setSelectedDigitalResources,
                                          formObj.selectedDigitalResources,
                                          resource.resource
                                        );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={resource.id + resource.resource}
                                >
                                  {resource.resource}
                                </label>
                                <span className="hidden">
                                  {resource.description}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </DigitalResources>
                )}

                {formObj.sendResource !== "false" && (
                  <PhysicalResources className="m-3 col">
                    <div>
                      <Labelspan>PHYSICAL RESOURCES</Labelspan>
                      {resources
                        .filter((resource) => resource.type === "physical")
                        .map((resource) => {
                          return (
                            <div
                              key={resource.id + resource.resource}
                              className="row form-check mb-2"
                            >
                              <div className="col">
                                <input
                                  type="checkbox"
                                  className="form-check-input physical-resources"
                                  id={resource.id + resource.resource}
                                  checked={formObj.selectedPhysicalResources
                                    .split(", ")
                                    .some((item) => {
                                      return item === resource.resource;
                                    })}
                                  onChange={(e) => {
                                    handleCheckboxChange(
                                      e,
                                      formObj.setSelectedPhysicalResources,
                                      formObj.selectedPhysicalResources,
                                      resource.resource
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={resource.id + resource.resource}
                                >
                                  {resource.resource}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div
                      className="rounded p-3"
                      style={{ border: "1px solid #2DB3C0" }}
                    >
                      <h6 className="mb-3">
                        TO BE SENT VIA MAIL<Redish>*</Redish>
                      </h6>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="mailingAddress">
                            <h6> MAILING ADDRESS</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            onChange={(e) => formObj.setAddress(e.target.value)}
                            value={formObj.address}
                          />
                        </div>
                      </div>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="city">
                            <h6>CITY</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            onChange={(e) =>
                              formObj.setUserCity(e.target.value)
                            }
                            value={formObj.userCity}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="province">
                            <h6>PROVINCE / STATE</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="province"
                            onChange={(e) =>
                              formObj.setUserStateProv(e.target.value)
                            }
                            value={formObj.userStateProv}
                          />
                        </div>
                      </div>
                      <div className="row form-group mb-2">
                        <div className="col">
                          <label htmlFor="postalCode">
                            <h6>POSTAL CODE / ZIP</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postalCode"
                            onChange={(e) =>
                              formObj.setUserZipPostal(e.target.value)
                            }
                            value={formObj.userZipPostal}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="country">
                            <h6>COUNTRY</h6>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="country"
                            onChange={(e) =>
                              formObj.setUserCountry(e.target.value)
                            }
                            value={formObj.userCountry}
                          />
                        </div>
                      </div>
                      <div className="row form-check mb-3">
                        <div className="col">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="isHouse"
                            checked={formObj.isHouse}
                            onChange={(e) =>
                              formObj.setIsHouse(e.target.checked)
                            }
                          />
                          <label className="form-check-label" htmlFor="isHouse">
                            Address is an apartment?
                            {formObj.isHouse && (
                              <span>
                                {" "}
                                (
                                <small
                                  id="mailingAddress"
                                  className="form-text text-muted"
                                >
                                  Request unit number
                                </small>
                                )
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </PhysicalResources>
                )}
              </div>
            </div>
            <ButtonWrapper className="mb-5">
             
              
             {(followUp.call_details.status !== "2" ||( followUp.call_details.status === "2" && hasCheckin)) && (
                  <CallButton
                    onClick={(e) => handleMakeCall(followUp)}
                    className="p-2 m-2"
                    disabled={isOnCall}
                  >
                    CALL BACK NOW
                  </CallButton>
             )}
 {(followUp.call_details.status !== "2") && (
    <>
                  <UpdateButton
                    onClick={(e) => scheduleFollowUp(e, 2)}
                    className="p-2 m-2"
                  >
                    SCHEDULE FOLLOW UP
                  </UpdateButton>
                  <UpdateButton
                    onClick={(e) =>
                      updateFollowUpStatus(e, followUp.call_details.status)
                    }
                    className="p-2 m-2"
                  >
                    UPDATE FOLLOW UP
                  </UpdateButton>
 </>    )}
                  {(followUp.call_details.status !== "2" ||( followUp.call_details.status === "2" && hasCheckin)) && (
                  <CompleteButton
                    onClick={(e) => {
                      closeFollowUp(e, 2);
                      //navigate(-1);
                    }}
                    className="p-2 m-2"
                  >
                    CLOSE FOLLOW UP
                  </CompleteButton>
                  )}
               
              
            </ButtonWrapper>
          </Form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default ViewFollowUp;
const DigitalResources = styled.div``;
const PhysicalResources = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PastorCareWrapper = styled.div`
  width: 50%;
`;
const Labelspan = styled.span`
  color: #000;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const Form = styled.div``;
const BackLinkContainer = styled.div`
  button {
    color: #178b96;
    font-weight: 700;
    display: flex;
    font-family: 14px;
    letter-spacing: 0.2em;
    font-size: 0.9em;
    text-decoration: none;
  }
  svg {
    vertical-align: middle;
  }
`;
const CallerInfoWrapper = styled.div`
  width: 50%;
`;
const SpanContainer = styled.div`
  display: inline;
  span {
    background-color: #115057;
    color: white;
    border-radius: 8px;
    display: inline-block;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .disabled {
    background-color: grey;
  }
`;
const Button = styled.button`
  border-radius: 8px;
  display: block;
  border: 0;
  width: 70%;
  color: white;
`;

const CallButton = styled(Button)`
  background-color: #ff7234;
`;

const UpdateButton = styled(Button)`
  background-color: #115057;
`;
const CompleteButton = styled(Button)`
  background-color: #40d13d;
`;

const CallTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f9fa;
`;

const CallTypes = styled.div``;

const CallNotes = styled.div``;
const Redish = styled.span`
  color: #ec3310 !important;
`;
const FolowUpTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FolowUpTypes = styled.div``;

const SendViaWrapper = styled.div`
  border: 1px solid #2db3c0;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TypeCell = styled.td`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
`;
const Table = styled.table`
  width: 100%;
  font-family: Space Grotesk;
`;
const Th = styled.th`
  text-align: center;
`;
