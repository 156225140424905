import React from "react";
import {FollowUpList} from './follow-up-list';

export const AllFollowUp = () => {
 
    return (
    <>
    <FollowUpList Status="checkin" />
    </>
    );
}
export default AllFollowUp;