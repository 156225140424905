import React from "react";
import { MapContainer } from "react-leaflet";

export const ChurchLocation = () => {
    return (<>
    <div>you are in Church location!!!!</div>
        <MapContainer/>
        </>
    )
}
export default ChurchLocation;