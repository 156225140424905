import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import { convertSeconds } from "../../../helpers/utils";

const PastoralCalls = (props) => {
  const { getCallLogs} = useContext(PastoralContext);
  const [calls, setCalls] = useState([]);
  const [showCallHistory, setShowCallHistory] = useState(false);
// setUserNumber(props.userNumber);
useEffect(() => {
    getCallLogs({ direction: "outgoing",task_sid:props.task_sid }).then(
      (results) => {
        setCalls(results);
      }
    );
  },[]);
  return (
    <>
       {(calls && calls.total_count > 0) &&  
        <>
          <CallHistoryWrapper className="p-3 mx-5">
            <h6>FOLLOW UP HISTORY</h6>
            <div className="form-group row">
              <div className="col-sm-10">
                <CallHistoryButton
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={(e) => setShowCallHistory(!showCallHistory)}
                >
                  Confidential Follow Up History
                </CallHistoryButton>
              </div>
            </div>
          </CallHistoryWrapper>
          <CallHistoryTable
            className={showCallHistory ? "mx-5  table table-striped" : "d-none"}
          >
              <thead>
                <tr  key='header'>
                  <th>Follow-Up Date</th>
                  <th> Caller Name</th>
                  <th> Pastor</th>
                  <th>Call Duration</th>
                  <th>Aduio File</th>
                </tr>
              </thead>
              <tbody>
                { calls.total_count > 0 && calls.data.map((call,index) => {
                  return (
                    <tr key={+index} >
                      <td className="p-3 ">
                        {new Date(call.date).toLocaleDateString("en-US")}
                      </td>
                      <td className="">
                        {call.call_name }
                      </td>
                      <td className="">{call.agent_full_name}</td>
                      <td className="">{convertSeconds(call.call_duration).hours+':'+convertSeconds(call.call_duration).minutes+':'+convertSeconds(call.call_duration).seconds}</td>
                      <td className="">
                        <div >
                          
                            <audio
                              controls
                              className="m-1 w-11/12 p-0"
                              src={call.recording}
                              type="audio/ogg"
                            >
                              Your browser does not support the audio element.
                            </audio>
                         
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            
          </CallHistoryTable>
        </>
      } 
    </>
  );
};
export default PastoralCalls;

const CallHistoryWrapper = styled.div`
  h6 {
    margin: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffeae4;
  color: #ec3310;

  button {
    border-color: #ec3310;
    white-space: nowrap;
    text-align: center;
    color: #ec3310;
  }
  button:hover {
    border-color: #ff7234;
    color: #ff7234;
    background-color: #eac3b9;
  }
`;

const CallHistoryTable = styled.table`
  table-layout: fixed;
  width: auto;
  thead {
    background-color: #00323b;
    color: white;
  }
  td {
    white-space: nowrap;
    overflow: auto;
  }
`;

const CallHistoryButton = styled.button`
  font-weight: 600 !important;
  letter-spacing: 0.2em;
`;
