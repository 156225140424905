import React, { useContext, useEffect, useState } from "react";
import { PastoralContext } from "../../../contexts/PastoralContext";
import styled from "styled-components";
import { convertSeconds } from "../../../helpers/utils";
import "./../styles/PastoralCare.css";
import { ProfileContext } from "./../../../contexts/ProfileContext";
import PastoralPagination from "../components/pagination";
import FollowUpFilter from "./followUpFilter";
import { useNavigate } from "react-router-dom";

export const RecentCallList = (props) => {
  const { getCalls, getCallLogs,searchValue,sortOrder } = useContext(PastoralContext);
  const { worker } = useContext(ProfileContext);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [calls, setCalls] = useState([]);
  const user_name = worker.fullName;
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

 
  useEffect(() => {
    getCallLogs({ agent_name: user_name, direction: "outgoing",page:currentPage }).then(
      (results) => {
        setCalls(results);
      }
    );
  },[currentPage,searchValue,sortOrder]);
  return (
    <>
    
       <div className="flex flex-col w-full">
        <div className="justify-end">
        <FollowUpFilter showCallerUserNmber = {true}      
        showDateRange=   {true}/>
        </div>
      <Wrapper className="  m-5 px-3 ">
        <Header className="p-3 mt-4">
          <TableHeading> RECENT CALLS </TableHeading>
          <span></span>
        </Header>
        {calls ? (
          <>
            <Table className="px-3">
              <thead>
                <TableHeaderRow  key='header'>
                  <Th>Follow-Up Date</Th>
                  <Th> Caller Name</Th>
                  <Th> Phone Number</Th>
                  <Th>Call Duration</Th>
                  <Th>Aduio File</Th>
                </TableHeaderRow>
              </thead>
              <tbody>
                { calls.total_count > 0 && calls.data.map((call,index) => {
                  return (
                    <TableRow key={+index}  onClick={()=>navigate('/followups/'+call.call_details_id)}>
                      <td className="p-3 text-center">
                        {new Date(call.date).toLocaleDateString("en-US")}
                      </td>
                      <td className="text-center">
                        {call.call_name }
                      </td>
                      <td className="text-center">{call.user_number}</td>
                      <td className="text-center">{convertSeconds(call.call_duration).hours+':'+convertSeconds(call.call_duration).minutes+':'+convertSeconds(call.call_duration).seconds}</td>
                      <td className="text-center">
                        <div >
                          
                            <audio
                              controls
                              className="m-1 w-11/12 p-0"
                              src={call.recording}
                              type="audio/ogg"
                            >
                              Your browser does not support the audio element.
                            </audio>
                         
                        </div>
                      </td>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <div
              className="spinner-border"
              role="status"
              style={{ width: "6em", height: "6em" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
   </Wrapper>
      {(calls && calls.total_count > itemsPerPage)&& (
     
      <PastoralPagination 
       currentPage ={currentPage}
       itemsPerPage ={itemsPerPage}
       total_count ={ calls.total_count}
      handlePageChange ={handlePageChange}
      />
      )}   
      </div>
    </>
  );
};

export default RecentCallList;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;
const TableHeaderRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
`;

const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  cursor:pointer;
    &:hover{
  background-color:#F1F1F1;
  }
`;
const Table = styled.table`
  width: 100%;
`;
const Th = styled.th`
  text-align: center;
`;
const Header = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
`;
