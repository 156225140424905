// src/components/TimeSlotPicker.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";

const TimeSlotPicker = (props) => {
  const [interval, setinterval] = useState(60);
  const [slots, setSlots] = useState([]);
  const selectedDate = props.selectedDate;
  const [selectedSlot, setSelectedSlot] = useState(null);
  const handleTimeChange = props.handleTimeChange;
    
  const generateTimeSlots = (interval) => {
    const slots = [];
    for (let hour = 9; hour < 22; hour++) {
      for (let minutes = 0; minutes < 60; minutes += interval) {
        const time = new Date(selectedDate);
        time.setHours(hour, minutes, 0, 0);
        slots.push(time);
      }
    }
    return slots;
  };
  useEffect(() => {
    
    setSlots(generateTimeSlots(interval));
  }, [selectedDate,interval]);

  

  const handleClick = (slot) => {
    setSelectedSlot(slot);
    handleTimeChange(slot);
  };

 

  return (
    <Container className="flex-col gap-4">
      <Labelspan>AVAILABLE TIMES:</Labelspan>
      <div className="flex flex-row gap-1 flex-wrap">
        {slots.map((slot, index) => (
          <Slot
            key={index}
            selected={slot === selectedSlot}
            onClick={() => handleClick(slot)}
          >
            
            {String(new Date(slot).getHours()).padStart(2, '0')}:{String(new Date(slot).getMinutes()).padStart(2, '0')}
          </Slot>
        ))}
      </div>

    </Container>
  );
};

export default TimeSlotPicker;

const Labelspan = styled.span`
  color: #00323b;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Slot = styled.div`
  background-color: ${(props) => (props.selected ? "#4CAF50" : "#f1f1f1")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
  text-align: center;
`;
