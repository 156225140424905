import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";

export const ExportButton = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const handleClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleMenuItemClick = () => {
    setOpenMenu(false); // Hide menu after selecting an item
  };
  return (
    <div>
      {/* <CustomedButton className="px-5 py-2" onClick={handleClick}>
        Export Options
        <FaChevronDown className={`h-3.5 w-3.5 transition-transform ${openMenu ? "rotate-180" : "" }`} />
      </CustomedButton>
       <Menu  open={openMenu}>
       <MenuItem>Print PDF</MenuItem>
       <MenuItem>Export as CSV File</MenuItem>
       </Menu> */}

      <MenuContainer>
        <CustomedButton onClick={handleClick} className="px-5 py-2">
          Export Options
          <FaChevronDown
            className={`h-3.5 w-3.5 transition-transform ${
              openMenu ? "rotate-180" : ""
            }`}
          />
        </CustomedButton>
        <DropdownMenu show={openMenu}>
          <MenuItemm href="#home" onClick={handleMenuItemClick}>
          Print PDF
          </MenuItemm>
          <MenuItemm href="#about" onClick={handleMenuItemClick}>
          Export as CSV File
          </MenuItemm>
        </DropdownMenu>
      </MenuContainer>
    </div>
  );
};
export default ExportButton;

const CustomedButton = styled.button`
  display: flex;
  flex-flow: row;
  flex-shrink: 0;
  border-radius: 8px;
  justify-content: center;
  border: 1px #2db3c0 solid;
  color: #2db3c0;
  text-align: center;
  align-items: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2.1px;

  flex-shrink: 0;
`;
const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #0056b3;
  }
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 1000;
`;

const MenuItemm = styled.a`
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px;
  border-bottom: 1px solid #444;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #555;
  }
`;
