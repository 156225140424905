import React, { useState, useContext } from "react";
import { Icon } from "@mui/material";
import { FiMic, FiMicOff, FiPause } from "react-icons/fi";
import { MdOutlineDialpad } from "react-icons/md";
import { PiShareFat, PiPhoneDisconnect } from "react-icons/pi";
import { PastoralContext } from "./../../../contexts/PastoralContext";

const CallButtons = (props) => {
  const { callSettings, setIsOnCall, isMute, setIsMute } =
    useContext(PastoralContext);

  const [showHangupButton, setShowHangupButton] = useState(
    props.showHangupButton !== undefined ? props.showHangupButton : true
  );

  var call = callSettings.call;
  var device = callSettings.device;

  const hangup = () => {
    if (call) {
      call.disconnect();
      device.destroy();
    }
    setIsOnCall(false);
  };

  const handleHold = () => {
    if (call) {
    }
    setIsOnCall(false);
  };

  const handleMute = () => {
    if (call) {
      call.mute(!isMute);
      setIsMute(!isMute);
    }
  };
  
  return (
    <div className="flex-row flex bg-transparent gap-3 m-2">
      <button className="bg-slate rounded-3xl p-2" onClick={handleMute}>
        <Icon>{!isMute ? <FiMic /> : <FiMicOff />}</Icon>{" "}
      </button>
      <button className="bg-slate rounded-3xl p-2">
        <Icon>
          <FiPause />
        </Icon>{" "}
      </button>
      <button className="bg-slate rounded-3xl p-2">
        <Icon>
          <PiShareFat />
        </Icon>{" "}
      </button>
      <button className="bg-slate rounded-3xl p-2">
        <Icon>
          <MdOutlineDialpad />
        </Icon>{" "}
      </button>
      {showHangupButton && (
        <button className="bg-ikura rounded-3xl p-2" onClick={hangup}>
          <Icon>
            <PiPhoneDisconnect />
          </Icon>{" "}
        </button>
      )}
    </div>
  );
};

export default CallButtons;
