import React, { useState, useContext, useEffect } from "react";
import Charts from "./charts";
import ReportCard from "./reportCard";
import { PastoralContext } from "./../../../contexts/PastoralContext";

const Reports = () => {
     // Data for the chart  
    //  const data ={'salvation':[30, 45, 60, 50, 70, 65, 80, 90, 85, 75, 60, 55],
    //     'rededication':[30, 45, 60, 50, 70, 65, 80, 90, 85, 75, 60, 55],
    //     'prayer':[30, 45, 60, 50, 70, 65, 80, 90, 85, 75, 60, 55]
    //  };
    const [data,setData] =useState([]);
    const [totals,setTotals] =useState([]);
     const {getReportsData } = useContext(PastoralContext);
     useEffect(()=>{
        getReportsData().then((result) => {
var data={
    'Salvation': result.Salvation,
    'Rededication':result.Rededication,
    'Prayers':result.Prayers
}
            setData(data);
            data={
                'Prayer_count':result.Prayer_count,
                'Rededication_count':result.Rededication_count,
                'Salvation_count':result.Salvation_count
        }
            setTotals(data);
        });   
    },[])

   
   
    return (
        <div className="columns-2">
          
            <Charts data={data} title="CONFIRMED IN THE PREVIOUS MONTHS" />
            <ReportCard totals={totals}/>
        </div>
    );
    };

export default Reports;