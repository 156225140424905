import React, { useContext } from "react";
import CallerDetails from "./callerDetailsComponent";
import { Icon } from "@mui/material";
import { FiFileText } from "react-icons/fi";
import CallButtons from "./callButtons";
import Timer from "./timer";
import { useNavigate } from "react-router-dom";
import { PastoralContext } from "./../../../contexts/PastoralContext";

const OncallBar = () => {
  const navigate = useNavigate();
  const { callSettings } = useContext(PastoralContext);

  return (
    <div className="flex-row flex justify-between">
      <div className="flex flex-col gap-1 m-2">
        <span className=" text-[#40D13D] bg-[#D9F6D8] border-[#40D13D] rounded-2xl p-1 border-2 flex">
          CALL IN PROGRESS - <Timer />
        </span>
        <button
          onClick={() => {
            navigate("/followups/" + callSettings.callDetails.id);
          }}
          className="  bg-marina border-metallicSeaweed2 rounded-2xl p-1 border-2"
        >
          <Icon className="text-white">
            <FiFileText flip="horizontal" />{" "}
          </Icon>{" "}
          PASTORAL NOTES
        </button>
      </div>
      <CallerDetails />
      <CallButtons />
    </div>
  );
};

export default OncallBar;