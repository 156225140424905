import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CallFormContext } from "./../../../contexts/CallFormContext";

const CallHistory = (props) => {
  const [userNumber] = useState(props.userNumber);
  const { getHistory } = useContext(CallFormContext);
  const [callerHistory, setCallerHistory] = useState("");
  const [showCallHistory, setShowCallHistory] = useState(false);
// setUserNumber(props.userNumber);
  useEffect(() => {
    getHistory(props.userNumber).then((result) => {
      setCallerHistory(result);
    });
  }, [userNumber]);
  return (
    <div className="px-5">
      {(callerHistory && callerHistory.length > 0) && 
        <>
          <CallHistoryWrapper className="p-3">
            <h6>CALL HISTORY</h6>
            <div className="form-group row">
              <div className="col-sm-10">
                <CallHistoryButton
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={(e) => setShowCallHistory(!showCallHistory)}
                >
                  Confidential Call History
                </CallHistoryButton>
              </div>
            </div>
          </CallHistoryWrapper>
          <CallHistoryTable
            className={showCallHistory ? "  table table-striped" : "d-none"}
          >
            <thead>
              <tr>
                <th className="row-date">Call Date</th>
                <th className="row-type">Call Type </th>
                <th className="row-disposition">Disposition</th>
                <th className="row-notes">Notes</th>
                <th className="row-location">Pastoral notes</th>
              </tr>
            </thead>

            <tbody>
              {callerHistory.map((item,index) => {
                if (!item.call_details.task_sid) return null;
                const day = new Date(item.call_details.created_at);
                const m = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];
                const str_op =
                  day.getDate() +
                  " " +
                  m[day.getMonth()] +
                  " " +
                  day.getFullYear();

                return (
                  <tr key={item.call_details.task_sid +'-'+ index}>
                    <td className="w-1/12">{str_op}</td>
                    <td className="w-1/4">
                      {item.call_details.call_type
                        ? item.call_details.call_type
                        : ""}
                    </td>
                    <td className="w-1/12">
                      {item.call_details.disposition
                        ? item.call_details.disposition
                        : ""}
                    </td>
                    
                    <td className="w-1/3">
                      {item.call_details.notes ? item.call_details.notes : ""}
                    </td>
                    <td className="w-1/3">
                      {item.call_details.pastoral_notes ? item.call_details.pastoral_notes : ""}
                    </td>
                    
                  </tr>
                );
              })}
            </tbody>
          </CallHistoryTable>
        </>
      }
    </div>
  );
};
export default CallHistory;

const CallHistoryWrapper = styled.div`
  h6 {
    margin: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffeae4;
  color: #ec3310;

  button {
    border-color: #ec3310;
    white-space: nowrap;
    text-align: center;
    color: #ec3310;
  }
  button:hover {
    border-color: #ff7234;
    color: #ff7234;
    background-color: #eac3b9;
  }
`;

const CallHistoryTable = styled.table`
  table-layout: fixed;
  width: auto;
  border-collapse: collapse;
  thead {
    background-color: #00323b;
    color: white;
  }
  td {
    word-wrap: break-word;
      word-break: break-word;
      white-space: normal;
      padding: 8px;
    overflow: auto;
  }
`;

const CallHistoryButton = styled.button`
  font-weight: 600 !important;
  letter-spacing: 0.2em;
`;
