import React, { useContext,  useState,useEffect } from 'react'
import styled from 'styled-components';
import { PastoralContext } from './../../../contexts/PastoralContext';
import PastoralPagination from '../components/pagination';
import FollowUpFilter from '../components/followUpFilter';

export const MailingResources = () => { 
    

    const { getResources,searchValue,sortOrder} = useContext(PastoralContext);  
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1); 
    const [resources,  setResources] =useState([]);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    useEffect(()=>{

        getResources('New',currentPage).then((results)=>{
            setResources(results);
        })
        
    },[currentPage,searchValue,sortOrder])
    return(
        <><div className="justify-end">
        <FollowUpFilter showCallerUserNmber = {true} showCallerName={true} showAgentName ={true} />
        </div>
        <Wrapper className=' m-5 px-3'>
        
        <Header className='px-5 p-3 mt-4 justify-center'> 
            <TableHeading>OVERVIEW OF MAILING FOLLOW-UPS</TableHeading>

        </Header>
        {resources.data ?
        <> 
            <Table>
                <thead>
                    <TableHeaderRow >                    
                        <th> Creation Date</th>
                        <th> Caller Name</th>
                        <th> Phone Number</th>
                        <th>Resource Title</th>
                        <th> Prayer Partner</th>
                        <th> Status</th>                      
                    </TableHeaderRow>
                </thead>
                <tbody>
                {
                    resources.data.map((resource) => {
                        return (     
                        <TableRow key={resource.id}>
                            <td className='p-2'>{new Date(resource.created_at).toLocaleDateString(
                          "en-US"
                        )}</td> 
                            <td className='p-2 capitalize'>{resource.userName ? resource.userName.toLowerCase() : ""} </td>
                            <td className='p-2'>{resource.user_number? resource.user_number : ""}</td>
                            <td className='p-2'>{resource.title}</td>
                            <td className='p-2'>{resource.sent_by} </td>
                            <td className='p-3'>{resource.status}</td> 
                        </TableRow>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
       : <div className="d-flex justify-content-center" >
            <div className="spinner-border" role="status" style={{width: "6em", height: "6em"}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        }
    </Wrapper>
      {resources && (
          <PastoralPagination
          currentPage ={currentPage}
          itemsPerPage ={itemsPerPage}
          total_count ={ resources.total_count}
         handlePageChange ={handlePageChange}
         />
      )}
      </>
    )
}
export default MailingResources;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;


const TableHeaderRow=
styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
 
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  
  &:hover{
  background-color:#F1F1F1;
  }
`;
const Table = styled.table`
  width: 100%;
   font-family: Space Grotesk;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;

`;






