// Timer.js
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { PastoralContext } from "./../../../contexts/PastoralContext";

// Styled component for the timer display
const TimerDisplay = styled.div`
//   font-family: 'Arial', sans-serif;
//   font-size: 2rem;
//   color: #333;
//   background-color: #f4f4f4;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   text-align: center;
 `;

// Timer component
const Timer = () => {
 const {seconds, setSeconds} = useContext(PastoralContext);

  useEffect(() => {
    // Set up an interval that updates the seconds every second
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
      setSeconds(0);
     // console.log('Counter stopped and interval cleared.');
    };
  }, []);

  // Format seconds into minutes and seconds
  const formatTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const remainingSeconds = secs % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return <TimerDisplay>{formatTime(seconds)}</TimerDisplay>;
};

export default Timer;