import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PrayerPartnerContext } from "../contexts/PrayerPartnerContext";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "./pagination";
export const Testimony = ( ) => {
    const { fetchTestimony }  = useContext(PrayerPartnerContext);
    const [currentPage, setCurrentPage] = useState(1);

   // const organizationName = worker?.organizationName
    const getTestimony = () =>{
        return fetchTestimony(currentPage)
    }
    const { isPending, status,  isError, data, error } = useQuery({
        queryKey: ['testimonies'],
        queryFn:  getTestimony
       // enabled: !!organizationName ,
    })

    if (isPending) return <>Loading..</>;
    if (isError) return <></>;

    const testimonies = data.data
    return (
        <Wrapper>
            <header className="mb-3">
                <h2>Testimonials</h2>
            </header>

            { testimonies && 
                <div>
                    {testimonies.map((testimony, index) => {
                        return (
                            <TestimonyItem key={index}>
                                <p className="pt-2 px-4">{testimony.notes}</p>
                                <span className="p-1 flex justify-end">{testimony.caller_first_name} {testimony.caller_last_name}</span>
                            </TestimonyItem>
                        );
                    })
                    }
                </div>
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    header {
        text-align: center;
        background-color: #F1F1F1;
        color: #178B96;
        font-family: Futura PT;
    }
`;

const TestimonyItem = styled.div`
    border-bottom: 0.5px solid black;
    p {
        text-align: center;
    }

`;