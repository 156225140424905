import "./styles/App.css";
import React  from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import ProtectedRoute from "./ProtectedRoute";
import { routesConfig } from "./routesConfig";
import { PageLoader } from "./components/page-loader";
import { ToastContainer } from "react-toastify";
import { ViewProfile } from "./pages/view-profile";
import { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`
     body {
    margin: 0;
    font-family: Arial, sans-serif;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #FAF8F8;
  }

  .app {
    display: flex;
    flex: 1;
  }
`;
export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <>
      <GlobalStyle />
      {/* <PageLayout> */}
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/callback" element={<HomePage />} />
        {routesConfig.map((route, index) => (
          <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute
                    element={route.element}
                    allowedRoles={route.allowedRoles}
                />
              }
          />
        ))}
        <Route
          path="*"
          element={<NotFoundPage errorMessage="Page not Found" />}
        /> 
        <Route
          path="not-found"
          element={<NotFoundPage errorMessage="Page not Found" />}
        /> 
      </Routes>
      <ToastContainer />
      <ViewProfile />
    </>
  );
};
