import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ProfileContext } from "./../../../../contexts/ProfileContext";
import { PastoralContext } from "./../../../../contexts/PastoralContext";
import { PiCaretDownThin } from "react-icons/pi";
import { FiFilePlus } from "react-icons/fi";
import { Icon } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import OncallBar from "../../components/onCallBar";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const { worker } = useContext(ProfileContext);
  const {
    getFollowUps,
    getResources,
    isOnCall,
    activeTopBar,
    setActiveTopBar,
    setActiveSideBar,
  } = useContext(PastoralContext);
  const name = worker.fullName;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [newFollowUpCount, setNewFollowUpCount] = useState(0);
  const [ongoingFollowUpCount, setOngoingFollowUpCount] = useState(0);
  const [completedFollowUpCount, setCompletedFollowUpCount] = useState(0);
  const [mailingResourceCount, setMailingResourceCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      // Fetch all follow-up and resource data in parallel
      Promise.all([
        getFollowUps("new"),
        getFollowUps("completed"),
        getFollowUps("ongoing"),
        getResources('New')
      ]).then(([newData, completedData, ongoingData, resourceData]) => {
        setNewFollowUpCount(newData.total_count);
        setCompletedFollowUpCount(completedData.total_count);
        setOngoingFollowUpCount(ongoingData.total_count);
        setMailingResourceCount(resourceData.total_count);
      }).catch((error) => {
        console.error('Error fetching data:', error);
      });
    };
     // Call fetchData initially
     fetchData();

     // Set up polling (e.g., every 10 seconds)
     const timeKeeper = setInterval(fetchData, 30000);
 
     // Cleanup function to clear the interval when the component is unmounted
     return () => clearInterval(timeKeeper);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClick = (index, content) => {
    setActiveTopBar(index);
    setActiveSideBar(2);
    navigate(content);
  };

  const { logout } = useAuth0();
  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <NavbarContainer>
      <FirstRow>
        <Greeting>
          <WelcomeMessage> Welcome back, {name}!</WelcomeMessage>
          <SubWelcomeMessage> PASTORAL CARE DASHBOARD</SubWelcomeMessage>
        </Greeting>
        <UserProfile>
          <DropdownToggle onClick={toggleDropdown}>
            <Icon>
              <img
                src={require("./../../../../assets/images/UserCircle.png")}
                alt="user icon"
              />
            </Icon>
            {name}
            <PiCaretDownThin style={{ marginLeft: "8px", color: "00323B" }} />
          </DropdownToggle>
          <DropdownContainer
            style={{ display: isDropdownOpen ? "block" : "none" }}
          >
            {/* <DropdownItem onClick={toggleDropdown}>Accounts</DropdownItem>
            <DropdownItem onClick={toggleDropdown}>Settings</DropdownItem> */}
            <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
          </DropdownContainer>
        </UserProfile>
      </FirstRow>

      {!isOnCall  ? ( //add the "|| (isOnCall && activeSideBar === 3)" when dailer logic is added
        <NavLinks className="gap-3">
          {/* <NavLink
            className="create"
            style={{ backgroundColor: "#ADCFD4" }}
            key={0}
            onClick={() => handleClick(0, "followups")}
          >
            <FiChevronsLeft style={{ height: "24px", width: "24px" }} />
          </NavLink> */}

          <NavLink
            key={1}
            style={{
              backgroundColor: 1 === activeTopBar ? "#106271" : "white",
              color: 1 === activeTopBar ? "white" : "#106271",
            }}
            onClick={() => handleClick(1, "../followups/new")}
          >
            <NavCount>{newFollowUpCount}</NavCount>
            <span>NEW FOLLOW-UPS</span>
          </NavLink>

          <NavLink
            key={2}
            style={{
              backgroundColor: 2 === activeTopBar ? "#106271" : "white",
              color: 2 === activeTopBar ? "white" : "#106271",
            }}
            onClick={() => handleClick(2, "../followups/ongoing")}
          >
            <NavCount>{ongoingFollowUpCount}</NavCount>
            <span>ONGOING FOLLOW-UPS</span>
          </NavLink>

          <NavLink
            key={3}
            style={{
              backgroundColor: 3 === activeTopBar ? "#106271" : "white",
              color: 3 === activeTopBar ? "white" : "#106271",
            }}
            onClick={() => handleClick(3, "../followups/completed")}
          >
            <NavCount>{completedFollowUpCount}</NavCount>
            <span>COMPLETED FOLLOW-UPS</span>
          </NavLink>

          <NavLink
            key={4}
            style={{
              backgroundColor: 4 === activeTopBar ? "#106271" : "white",
              color: 4 === activeTopBar ? "white" : "#106271",
            }}
            onClick={() => handleClick(4, "../mailing")}
          >
            <NavCount>{mailingResourceCount}</NavCount>
            <span>MAILING RESOURCES</span>
          </NavLink>

          <NavLink
            className="create"
            key={5}
            onClick={() => handleClick(5, "../callForm")}
          >
            <FiFilePlus style={{ height: "24px", width: "24px" }} />
          </NavLink>
        </NavLinks>
      ) : (
        <OncallBar />
      )}
    </NavbarContainer>
  );
};

// Styled components
const NavbarContainer = styled.nav`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #d9eef0;
  color: white;
  padding: 20px 10px 20px 10vw;
  position: fixed;
  width: 100%;
  top: 0;
  margin-left: 170px;
  height: 201px;
  right: 0;
  z-index: 1;
`;

const Greeting = styled.div`
  display: flex;
  height: 70px;
  flex-direction: column;
`;

const NavLinks = styled.ul`
  display: flex;
  justify-content: space-evenly;
  padding-right:2rem;
`;

const NavLink = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  background-color: white;

  height: 66px;
      min-width: 165px;
  justify-content: center;
  border-radius: 20px;
  flex-direction: column;
  flex-grow:1;

  &.create {
    color: white;
    display: flex;
    min-width: 34.5px;
    height: 66px;
    padding: 21px 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-grow: 0;
    background: #ff7234;
  }

  &:hover {
    cursor: pointer;
  }
`;
const NavCount = styled.span`
  text-align: center;
  font-family: Recoleta;
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
`;

const WelcomeMessage = styled.span`
  color: #00323b;
  font-family: DM Serif Display;
  font-size: 30px;
`;
const SubWelcomeMessage = styled.span`
  color: #2db3c0;
  font-size: 14px;
  font-family: Space Grotesk;
`;
const UserProfile = styled.div``;

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  background-color: white;
  color: #00323b;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 2000;
  margin-right: 30px;
`;

const DropdownItem = styled.a`
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    background-color: #555;
  }
`;

const DropdownToggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #00323b;
  font-family: "Futura PT";
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 125%;
  margin-right: 30px;
`;
const FirstRow = styled.div`
  justify-content: space-between;
  display: flex;
  flex: row;
`;

export default Topbar;
