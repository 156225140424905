import React, { useContext } from 'react';
import styled from 'styled-components';
import { PastoralContext } from './../../../../contexts/PastoralContext';
import { Link } from 'react-router-dom';

const Sidebar =  () => {
  const { activeSideBar, setActiveSideBar, setActiveTopBar} = useContext(PastoralContext);
 

  const handleClick = (index, content) => {
    setActiveSideBar(index);
    setActiveTopBar(0);
  };
  const sidebarItems = [
    {id:1, name: '/profile',  content: 'home',image:"home.png" },
    {id:2, name: '/followups', content: 'AllFollowUp' ,image:"hands.png" },
    {id:3, name: '/calllogs', content: 'CallLog',image:"headset.png" },
    // {id:4, name: 'Location',  content: 'ChurchLocation',image:"mapPin.png" },
    ];

  return (
    
      <SidebarContainer >
        <LogoContainer>
            <img  src= {require("./../../../../assets/images/a1pLogo.png")} alt="logo" width={'48'} />
        </LogoContainer>                
        <SidebarMenu>
        {sidebarItems.map((item, index) => (
          <Link to={item.name}  key={item.id}>
          <MenuItem
           key={item.id}
          //active={item.id === activeSideBar? true : undefined}
          style={{backgroundColor: (item.id===activeSideBar) ? '#F4E5E2' : 'transparent'}}
          onClick={() => handleClick(item.id, item.content)}
          ><img  src= {require("./../../../../assets/images/"+ item.image)} alt="logo" width={'48'} />
         
         </MenuItem>
         </Link>
        ))}
          
        </SidebarMenu>
      </SidebarContainer>
  
  );
};
// Styled components
const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 9vw;
  background-color: #FAF1EF;
  color: white;
  z-index: 1000;
  height: 100vh;
   display:flex;
   flex-flow:column;
    
`;

const SidebarMenu = styled.ul`
  list-style: none;
  padding: 0;
  display:flex;
  height:90vh;
    flex-flow: column;
    flex-shrink: 1;
    
`;

const MenuItem = styled.div`
display:flex;
  height:25vh;
  align-items: center;
  flex-shrink: 1;
justify-content: center;
 
 &:hover {
    text-decoration: underline;
    background-color: #F4E5E2;
    cursor:pointer;
  }
`;


const LogoContainer =styled.div`
display: flex;
height: 115px;
width: 100%; 
align-items: center;
justify-content: center;
`;

export default Sidebar;