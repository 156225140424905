import React from "react";
import Scheduler from "../components/Scheduler";
import Reports from "../components/reports";
export const PastoralHome = () => {
  return (
    <>
      <Scheduler />
      <Reports />
    </>
  );
};

export default PastoralHome;
