import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { ProfileContext } from './contexts/ProfileContext';
import LayoutWrapper from './LayoutWrapper';
import { LoadingSpinner } from './components/loading-spinner';
import { LoginButton } from './components/buttons/login-button';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ element, allowedRoles }) => {
    const { user, isAuthenticated, isLoading: authLoading } = useAuth0();
    const { worker, loading: workerLoading } = useContext(ProfileContext);

    // Show a loading spinner if Auth0 is still checking or fetching worker metadata
    if (authLoading || workerLoading) {
        return <LoadingSpinner />;
    }

    // If user is not authenticated, prompt login
    if (!isAuthenticated || !user) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <p>Please log in to access this page.</p>
                <LoginButton />
            </div>
        );
    }

    // If worker metadata is not loaded (meaning user has no associated profile data), also show login prompt
    if (!worker) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <p>Unable to load user profile. Please try logging in again.</p>
                <LoginButton />
            </div>
        );
    }

    // Check if the user's role is allowed to access the page
    if (allowedRoles && !allowedRoles.includes(worker.role)) {
        return <Navigate to="/not-found" replace />;
    }

    // Render the protected component within the layout wrapper
    return <LayoutWrapper role={worker.role}>{element}</LayoutWrapper>;
};

export default ProtectedRoute;
