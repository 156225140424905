import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export default function ConfirmDialog({show,
  onHide,
  title = "Confirm Action",
  message = "Are you sure you want to proceed?",
  cancelButtonText="No",
  confirmButtonText="Yes",
  onConfirm}) {
  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide(false)}
        centered
      >
        <StyledModalHeader closeButton  >
          <Modal.Title className="font-space">{title}</Modal.Title>
        </StyledModalHeader>
        <Modal.Body className="flex justify-center bg-[#F8F3F2] p-0">
          <p className="text-richBlack font-space text-base pt-5 font-bold">{message}</p>
        </Modal.Body>
        <StyledModalFooter className="gap-5 pb-10 ">
          <button variant="primary"  className="bg-marina text-white border-r-5 px-20 py-2 font-space rounded-lg" onClick={(e)=>onConfirm(e,true)}>
                    {confirmButtonText}
                </button>
          <button variant="secondary" className="bg-ikura text-white border-r-5 px-20 py-2 font-space rounded-lg" onClick={(e) =>{ onHide(false);onConfirm(e,false);}}>
                    {cancelButtonText}
                </button>
                
        </StyledModalFooter>
      </Modal>
    </>
  );
}
// Create a styled component for Modal.Header
const StyledModalHeader = styled(Modal.Header)`
  background-color: #00323B; 
  color: white; 
  border-bottom: 1px solid #00323B;
  font-family:Space Grotesk;
  font-size:14px;
  
  &.btn-close {
    color: white; /* Style the close button icon if needed */
   
  }
`;

const StyledModalFooter = styled(Modal.Footer)`
  border:0;
  justify-content: center;
  background-color: #F8F3F2;
  padding-bottom: 2.5rem;
`;


