import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Scheduler from "../Scheduler";
import { showErrorMessage } from "../../../../helpers/useToast";

export default function FollowupScheduler(props) {
  const [followupDate, setFollowupDate] = useState();
  const handleUpdateRecord = () => {
   // console.log('FollowupScheduler',followupDate)
    if (followupDate) {
      props.updateRecord(); 
      props.onHide(false);
    } else {
      showErrorMessage("Time slot needs to be selected");
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title> Schedule a Follow Up Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Scheduler
            selectedDate={props.selectedDate}
            setUpdatedFollowUpDate={(date)=>{setFollowupDate(date); props.setUpdatedFollowUpDate(date)}}
            showTimeSlots={true}
            MinDate={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="churchreferrals text-white p-2 px-5  status"
            onClick={() => {
              handleUpdateRecord();
            }}
          >
            Update Follow Up
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
