import React, { useEffect, useContext, useState } from "react";
import styled from 'styled-components';
import { PrayerPartnerContext } from "../contexts/PrayerPartnerContext";
import { Pagination } from "../components/pagination";
import { Filter } from "../components/filter";
import moment from 'moment-timezone';

export const CallLogsPage = () => {
    const {callLogs, fetchCallLogs, totalLogsCount} = useContext(PrayerPartnerContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterString, setFilterString] = useState("");


    useEffect(()=>{
        fetchCallLogs(currentPage,filterString);
        window.scrollTo({top: 0, behavior: "smooth"});
    },[currentPage,filterString])

    return (
        <div className="m-3">
            <Filter setFilterString={setFilterString} page={"callLogs"}/>
            {callLogs &&
                <Table className="p-4">
                    <thead>
                        <tr>
                            <th>Call Date & Time</th>
                            <th>Agent's Name</th>
                            <th>Caller Number</th>
                            <th>Organization Number</th>
                            <th>Recording</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            callLogs.map((callLog) =>{ 
                                return (
                                    
                                    <tr key={callLog.task_sid}>
                                        <td>
                                            {/* Convert the datetime from utc to est using moment */}
                                            {moment.utc(callLog.date).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}
                                        
                                        </td>
                                        <td>{callLog.agent_full_name}</td>
                                        <td>{callLog.user_number}</td>
                                        <td>{callLog.twilio_number}</td>
                                        <td>
                                            <audio controls className="m-0 p-0">
                                                <source src={callLog.recording} type="audio/ogg"/>
                                                <source src={callLog.recording} type="audio/mpeg"/>
                                                Your browser does not support the audio element.
                                            </audio>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            }
            <Pagination totalCount={totalLogsCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    )
}

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            text-align: center;
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;