export const splitFullName = (fullName) =>{
    const nameParts = fullName.trim().split(/\s+/); // Split by one or more spaces
  
    let firstName = "";
    let lastName = "";
  
    if (nameParts.length > 1) {
      firstName = nameParts.slice(0, -1).join(" ");
      lastName = nameParts[nameParts.length - 1];
    } else if (nameParts.length === 1) {
      firstName = nameParts[0];
    }
  
    return { firstName, lastName };
}
  
  