import React from "react";
import {FollowUpList} from './follow-up-list';

export const NewFollowUp = () => {
    return (
    <>
        <FollowUpList Status='new'/>
        </>
    );
}
export default NewFollowUp;