import React from "react";
import {FollowUpList} from './follow-up-list';

export const OngoingFollowUp = () => {
    return (
    <FollowUpList Status="ongoing"/>
   
    );
}
export default OngoingFollowUp;

