import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewFollowUp from "../components/view-follow-up";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import ViewSucideFollowUp from "../components/view-suicide";

export const DisplayFollowUp = () => {
  const { getFollowUp, setFollowUp, followUp,isUpdated } = useContext(PastoralContext);
  const [isLoading, setIsLoading] = useState(true);
  var params = useParams();
  const id = params.followUpID;
  useEffect(() => {
    getFollowUp(id).then((result) => {
      if (result.error) {
      } else {
        setFollowUp(result);
        setIsLoading(false);
      }
    });
  }, [isUpdated]);
  return (
    <>
      {!isLoading && followUp ? (
        followUp.call_details.is_suicide_call === 1 ? (
          <ViewSucideFollowUp followUpID={id} followUp={followUp} />
        ) : (
          <ViewFollowUp followUpID={id} followUp={followUp} />
        )
      ) : (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{ width: "6em", height: "6em" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default DisplayFollowUp;
