import React from "react";
import Sidebar from "./navigation/side-bar/side-bar";
import Topbar from "./navigation/top-bar/top-bar";
import styled from "styled-components";

const RightSide = styled.div`
  display: flex;
  font-family: Space Grotesk;
`;
const MainContent = styled.main`
  margin-left: 9vw; /* Adjust this based on the sidebar width */
  padding-top: 220px; /* Adjust this based on the navbar height */
  // padding: 20px;
  font-family: Space Grotesk;
  flex: 1;
  background: #faf8f8;
`;

export const PageLayout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <RightSide>
        <Topbar  />
        <MainContent className="page-layout__content">
          {children}
        </MainContent>
      </RightSide>
    </>
  );
};
