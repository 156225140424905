import { Icon } from "@mui/material";
import React, { useContext } from "react";
import { FiPhoneCall, FiHome, FiEdit } from "react-icons/fi";
import { BsCheckCircle } from "react-icons/bs";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import styled from "styled-components";

const CallerDetails = (props) => {
  const { callSettings } = useContext(PastoralContext);
  const username =
    callSettings.callDetails?.caller_first_name ||
    callSettings.callDetails?.caller_last_name
      ? "Anonymous"
      : callSettings.callDetails?.caller_first_name +
        " " +
        callSettings.callDetails?.caller_last_name;
  const phoneNumber = callSettings.callDetails?.user_number;
  const street = callSettings.callDetails?.address
    ? "No Address "
    : callSettings.callDetails?.address;
  const suburb =
    callSettings.callDetails?.city ||
    callSettings.callDetails?.province ||
    callSettings.callDetails?.postal_zip
      ? "No Region "
      : callSettings.callDetails?.city +
        " " +
        callSettings.callDetails?.province +
        " " +
        callSettings.callDetails?.postal_zip;

  return (
    <div className="bg-richBlack rounded-3xl text-slate p-4 flex flex-row ">
      <Icon className="text-ikura">
        <FiPhoneCall />{" "}
      </Icon>
      <div className="flex flex-col px-2">
        <LabelSpan>{username}</LabelSpan>
        <LabelSpan>{phoneNumber}</LabelSpan>
      </div>
      <Icon className="text-ikura">
        <FiHome />{" "}
      </Icon>
      <div className="flex flex-col px-2">
        <LabelSpan>{street}</LabelSpan>
        <LabelSpan>{suburb}</LabelSpan>
      </div>
      <div className="flex flex-col px-2">
        <Icon className="text-limeGreen">
          <BsCheckCircle />{" "}
        </Icon>
        <Icon className="text-marina">
          <FiEdit />{" "}
        </Icon>
      </div>
    </div>
  );
};

export default CallerDetails;
const LabelSpan = styled.span`
  width: 190px;
`;
