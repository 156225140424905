import React, { useState } from "react";
import styled from "styled-components";

const ReportCard = (props) => {
    //console.log(props)
    return (
<CardContainer className='justify-center w-auto max-h-80 m-5 h-full'>
                        <LightCard className=" h-auto m-5 p-4 w-full justify-center">
                        <LightCardLabel className="text-ikura">TOTAL SALVATIONS / RE-DEDICATIONS</LightCardLabel>
                            <LightCardValue className="text-[#FF7234]">
                           {props.totals.Salvation_count}
                            </LightCardValue>
                            <LightCardLabel className="text-[#FF7234]">CONFIRMED SALVATIONS</LightCardLabel>
                        
                            <LightCardValue className="text-[#CF6537]">
                            {props.totals.Rededication_count}
                            </LightCardValue>
                            <LightCardLabel className="text-[#CF6537]">CONFIRMED RE-DEDICATIONS</LightCardLabel>
                            <LightCardValue className="text-[#CF3748]">
                            {props.totals.Prayer_count}
                            </LightCardValue>
                            <LightCardLabel className="text-[#CF3748]">CONFIRMED GENERAL PRAYERS</LightCardLabel>
                        </LightCard>
                    </CardContainer>
    )
};
export default ReportCard;

const CardContainer = styled.div`
    display:flex;
`;
const LightCard = styled.div`
    display: flex;
    flex-direction: column;
    
    background-color: white;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    
`;
const LightCardValue = styled.div`
   
text-align: center;
font-family: Recoleta;
font-size: 50px;
font-style: normal;
font-weight: 900;
line-height: normal;
`;

const LightCardLabel = styled.div`
 text-align: center;
font-family: "Space Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 3.5px;

`;