// TestimoniesPage, BlockNumberPage, 
import { SuperAdminPage } from './pages/super-admin-page';
import { SubscriberPage } from './pages/subscriber-page';
import { PrayerPartnerPage } from './pages/prayer-partner-page';
import { ViewProfile } from './pages/view-profile';
import { HomePage } from './pages/home-page';
import { NotFoundPage } from './pages/not-found-page';
import { InvoicePage } from './pages/invoice-page';
import { CallbackPage } from './pages/callback-page';
import { BlockCallerPage } from './pages/block-caller-page';
import { TestimonyPage } from './pages/testimony-page';
import { ProfilePage } from './pages/profile-page';
import { CallLogsPage } from './pages/call-logs-page';
import PastoralHome from './components/pastoral-care/pages/home';
import CallForm from './components/pastoral-care/pages/create-follow-up';
import NewFollowUp from './components/pastoral-care/pages/new-follow-up';
import OngoingFollowUp from './components/pastoral-care/pages/ongoing-follow-up';
import CompletedFollowUp from './components/pastoral-care/pages/completed-follow-up';
import AllFollowUp from './components/pastoral-care/pages/All-follow-up';
import CallLogs from './components/pastoral-care/pages/calllogs';
import MailingResources from './components/pastoral-care/pages/mailing-resources';
import ChurchLocation from './components/pastoral-care/pages/church-locations';
import { PastoralCare } from './components/pastoral-care/pastoral-care';
import DisplayFollowUp from './components/pastoral-care/pages/display-follow-up';
export const routesConfig = [
    {
        path: '/profile',
        element: <ProfilePage />,
        allowedRoles: ['admin', 'subscriber', 'super_admin', 'prayer_centre', 'pastoral_care']
    },
    {
        path: '/testimony',
        element: <TestimonyPage />,
        allowedRoles: ['prayer_centre', 'super_admin', 'admin']
    },
    {
        path: '/blockcaller',
        element: <BlockCallerPage />,
        allowedRoles: ['prayer_centre', 'subscriber', 'admin', 'super_admin']
    },
    {
        path: '/prayer-partner',
        element: <PrayerPartnerPage />,
        allowedRoles: ['prayer_centre', 'super_admin']
    },
    // {
    //     path: '/profile/',
    //     element: <PastoralHome />,
    //     allowedRoles: ['pastoral_care', 'super_admin']
    // },
    {
        path: '/followups/:followUpID',
        element: <DisplayFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups',
        element: <AllFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/new',
        element: <NewFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/ongoing',
        element: <OngoingFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/completed',
        element: <CompletedFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/calllogs',
        element: <CallLogs />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/callform',
        element: <CallForm />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/mailing',
        element: <MailingResources />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/profile/churches',
        element: <ChurchLocation />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/calllogs',
        element: <CallLogsPage />,
        allowedRoles: ['prayer_centre', 'super_admin']
    },
    {
        path: '/super-admin',
        element: <SuperAdminPage />,
        allowedRoles: ['super_admin']
    },
    {
        path: '/invoices',
        element: <InvoicePage />,
        allowedRoles: ['subscriber', 'admin', 'super_admin']
    },
    // {
    //     path: '/pastoral-care',
    //     component: PastoralCare,
    //     allowedRoles: ['pastoral_care']
    // },
    // {
    //     path: '*', // Catch-all route for 404 pages
    //     element: <NotFoundPage />,
    //     allowedRoles: ['subscriber', 'admin', 'super_admin', 'prayer_centre', 'pastoral_care']
    // }
];
