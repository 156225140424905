import React from "react";
import styled from "styled-components";
import { Pagination } from "rsuite";

export const PastoralPagination = (props) => {
  const currentPage = props.currentPage;
  const itemsPerPage = props.itemsPerPage;
  const total_count = props.total_count;
  const handlePageChange = props.handlePageChange;
  return (
    <PaginationSection className="gap-2">
      <PaginationText>
        Showing {(currentPage - 1) * itemsPerPage} to{" "}
        {currentPage * itemsPerPage < total_count
          ? currentPage * itemsPerPage + " of "
          : " "}
        {total_count} entries
      </PaginationText>
      <Pagination
        className="justify-center"
        total={total_count}
        activePage={currentPage}
        limit={itemsPerPage}
        maxButtons={5}
        size="md"
        next="NEXT  >>"
        prev="<<  PREVIOUS"
        ellipsis
        onChangePage={handlePageChange}
      />
    </PaginationSection>
  );
};

export default PastoralPagination;

const PaginationLink = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;
const PaginationText = styled.span`
  color: #000;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 4px;
`;
const PaginationSection = styled.div`
  width: fit-content;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
